/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';

export default Route.extend({
  //region Ember Hooks
  model() {
    return this.modelFor('organizations.organization.legal');
  },
  //endregion
});
