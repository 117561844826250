import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"template-list\">\n  {{#each this.galleryLayoutOptions as |layout|}}\n    <button type=\"button\" class=\"template-list__template template-list__template--enabled {{if (eq @value layout.value) \"template-list__template--current\"}}\" {{on \"click\" (fn @updateTokenContentProperty \"value\" layout.value)}}>\n      <div class=\"template-list__template-name\">{{layout.name}}</div>\n      <div class=\"template-list__template-overlay\">\n        <SsImg class=\"template-list__template-image\" @src={{layout.src}} @alt={{layout.name}} />\n      </div>\n    </button>\n  {{/each}}\n</div>\n", {"contents":"<div class=\"template-list\">\n  {{#each this.galleryLayoutOptions as |layout|}}\n    <button type=\"button\" class=\"template-list__template template-list__template--enabled {{if (eq @value layout.value) \"template-list__template--current\"}}\" {{on \"click\" (fn @updateTokenContentProperty \"value\" layout.value)}}>\n      <div class=\"template-list__template-name\">{{layout.name}}</div>\n      <div class=\"template-list__template-overlay\">\n        <SsImg class=\"template-list__template-image\" @src={{layout.src}} @alt={{layout.name}} />\n      </div>\n    </button>\n  {{/each}}\n</div>\n","moduleName":"partner/components/token-content-editor-for-gallery-layout.hbs","parseOptions":{"srcName":"partner/components/token-content-editor-for-gallery-layout.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type CurrentService from 'partner/services/current';
import type EnumsService from 'ember-cli-ss-enums/services/enums';

export default class TokenContentEditorForLayoutsComponent extends Component {
  @service declare current: CurrentService;
  @service declare enums: EnumsService;

  get galleryLayoutOptions() {
    if (this.current.organizationPromotion.promotion.isBallot) {
      return this.enums.data.BALLOT_LAYOUTS;
    }

    return this.enums.data.GALLERY_LAYOUTS;
  }
}
