import EmberRouter from '@ember/routing/router';
import { inject as service } from '@ember/service';

import config from './config/environment';

export default class Router extends EmberRouter {
  @service('sentry') sentry;
  @service('router') router;

  location = config.locationType;
  rootURL = config.rootURL;

  constructor(...args) {
    super(...args);

    this.on('routeDidChange', () => {
      if (this.router.currentRouteName) {
        this.sentry.setTag('route', this.router.currentRouteName);
      }
    });
  }
}

Router.map(function () {
  this.route('login', function () {
    this.route('forgot');
    this.route('choose-organization');
    this.route('totp');
    this.route('ucm');
  });

  this.route('logout');

  this.route('setup', function () {
    this.route('lockout-recovery');
    this.route('recovery');
    this.route('totp');
    this.route('totp-success');
  });

  this.route('orgs', { path: 'organizations' }, function () {
    this.route('organization', { path: ':organization_id' }, function () {
      this.route('dashboard');
      this.route('promotions');
      this.route('interactive-content');
      this.route('email');
      this.route('audiences');
    });
  });

  this.route('organizations', { path: 'o' }, function () {
    this.route('organization', { path: ':organization_id' }, function () {
      this.route('go', { path: 'go/:page' });
      this.route('dashboard');
      this.route('help-ticket');
      this.route('message-campaign-categories');
      this.route('messages');
      this.route('notifications');
      this.route('search');
      this.route('rules'); // Deprecated; redirects to o/:id/legal
      this.route('terms-of-service'); // Deprecated; redirects to o/:id/legal
      this.route('privacy-policy'); // Deprecated; redirects to o/:id/legal
      this.route('wrapper');
      this.route('feature-flags');
      this.route('forms', function () {
        this.route('form', { path: ':form_id' });
        this.route('new');
      });
      // Deprecated; these are redirects to email-templates.
      this.route('message-body-templates', function () {
        this.route('new');
        this.route('message-body-template', {
          path: ':message_body_template_id',
        });
      });
      this.route('email-templates', function () {
        this.route('new');
        this.route('email-template', {
          path: ':email_template_id',
        });
      });
      this.route('email', function () {
        this.route('message-body-templates', function () {
          this.route('new');
          this.route('message-body-template', { path: ':message_body_template_id' }, function () {
            this.route('edit');
          });
        });
        this.route('tokens');
        this.route('token-fallbacks');
      });
      this.route('message-campaigns', function () {
        this.route('archived');
        this.route('message-campaign', { path: ':message_campaign_id' }, function () {
          this.route('approvals', function () {
            this.route('approval', { path: ':message_version_history_id' });
          });
          this.route('dashboard');
          this.route('messages', function () {
            this.route('message', { path: ':message_id' }, function () {
              this.route('dashboard');
            });
          });
          this.route('report', function () {
            this.route('event', { path: ':user_messaging_statistic_type_id' });
          });
          this.route('schedule-instances', function () {
            this.route('schedule-instance', { path: ':schedule_instance_id' }, function () {
              this.route('dashboard');
            });
          });
          this.route('setup', function () {
            this.route('message-versions');
            this.route('recipients');
            this.route('schedule');
            this.route('confirmation');
            this.route('tags');
          });
        });
        this.route('performance-report');
      });
      this.route('sender-accounts');
      this.route('stripe');
      this.route('settings', function () {
        this.route('info', function () {
          this.route('edit-name');
          this.route('edit-time-zone');
          this.route('edit-optout-wrapper');
        });
        this.route('external-apps', function () {
          this.route('external-app', { path: ':external_app_id' });
          this.route('new');
        });
      });
      this.route('licenses', function () {
        this.route('license', { path: ':license_id' }, function () {
          this.route('cancel');
        });
      });
      this.route('data', function () {
        this.route('dashboard');
        this.route('tags', function () {
          this.route('taggable-entity-categories', { path: 'categories' }, function () {
            this.route('taggable-entity-category', {
              path: ':taggable_entity_category_id',
            });
          });
        });
        this.route('audiences', function () {
          this.route('audience', { path: ':audience_id' }, function () {
            this.route('filters');
            this.route('sales-sheet');
            this.route('copy');
            this.route('setup', function () {
              this.route('import');
              this.route('widget');
              this.route('email');
              this.route('sharing');
              this.route('integration');
            });
            this.route('dashboard');
            this.route('members');
          });
          this.route('new', { path: 'new/:audience_type_id' });
          this.route('archived');
        });
      });
      this.route('adestra', { path: 'data/audiences/adestra' }, function () {
        this.route('configure-fields');
      });
      this.route('interactive-content', function () {
        this.route('archived');
      });
      this.route('events', function () {
        this.route('archived');
      });
      this.route('ecommerce', function () {
        this.route('archived');
      });
      this.route('organization-promotions', { path: 'op' }, function () {
        this.route('archived');

        this.route('new', function () {
          this.route('archived');
          this.route('ugc');
          this.route('pickem');
          this.route('sweeps');
          this.route('turnkeys', function () {
            this.route('create', { path: ':promotion_preset_id' });
          });
        });
        this.route('organization-promotion', { path: ':organization_promotion_id' }, function () {
          this.route('dashboard', function () {
            this.route('sweepstakes');
            this.route('payment-form');
            this.route('survey');
            this.route('quiz');
            this.route('ugc-sweepstakes');
            this.route('ugc-voting');
            this.route('ugc-gallery');
            this.route('ballot');
            this.route('bracket');
            this.route('event');
            this.route('report');
            this.route('share');
          });
          this.route('winners');
          this.route('matchups', function () {
            this.route('matchup', { path: ':matchup_id' }, function () {
              this.route('entries');
            });
          });
          this.route('moderate-nominations', function () {
            this.route('approve');
            this.route('merge');
            this.route('review');
          });
          this.route('moderate-entries');
          this.route('moderate-people', function () {
            this.route('automatic');
            this.route('manual');
          });

          this.route('setup', function () {
            this.route('ballot', function () {
              this.route('matchup-groups', function () {
                this.route('new');
                this.route('edit', { path: ':matchup_group_id/edit' });
              });

              this.route('matchups', function () {
                this.route('new');
                this.route('edit', { path: ':matchup_id/edit' });
              });

              this.route('matchup-entries', function () {
                this.route('new');
                this.route('edit', { path: ':matchup_entry_id/edit' });
              });

              this.route('settings', function () {
                this.route('matchups');
                this.route('matchup-entries');
              });
            });

            this.route('bracket');
            this.route('dictionary');
            this.route('dates-prizes');
            this.route('entries');
            this.route('sweepstakes');
            this.route('publish');
            this.route('payment');
            this.route('ecommerce');
            this.route('thanks-sharing', function () {
              this.route('sweepstakes');
              this.route('quiz');
              this.route('survey');
              this.route('ugc-voting');
              this.route('ugc-gallery');
              this.route('ugc-sweepstakes');
            });
            this.route('legal');
            this.route('design');
            this.route('designs', function () {
              this.route('design', { path: ':design_id' });
            });
            this.route('quiz', function () {
              this.route('outcomes', function () {
                this.route('outcome', { path: ':outcome_id' });
                this.route('new');
              });
              this.route('questions', function () {
                this.route('question', { path: ':question_id' });
                this.route('new');
              });
              this.route('questions-new');
            });
            this.route('forms', function () {
              this.route('form', { path: ':form_id' });
              this.route('contact', { path: 'contact/:form_id' });
            });
            this.route('pages', function () {
              this.route('page', { path: ':page_id' });
            });
            // Deprecated; these are redirects to /emails
            this.route('messages', function () {
              this.route('message', { path: ':message_id' });
              this.route('new');
            });
            this.route('emails');
            this.route('tags');
            this.route('ads');
            this.route('ads-bracket');
          });
          this.route('ad-metrics');
          this.route('syndication-metrics');
        });
        this.route('performance-report');
      });
      this.route('tokens', function () {
        this.route('token', { path: ':token_id' });
      });
      this.route('people', function () {
        this.route('person', { path: ':consumer_user_id' }, function () {
          this.route('messaging');
          this.route('email-history');
          this.route('promotion-history');
        });
      });
      this.route('opt-out-preference-center');
      this.route('legal', function () {
        this.route('design-template', { path: ':design_template_id' }); // Deprecated; redirects to design-templates/design-template
        this.route('new', { path: 'new/:design_template_type_id' }); // Deprecated; redirects to design-templates/new
        this.route('design-templates', function () {
          this.route('design-template', { path: ':design_template_id' });
          this.route('new', { path: 'new/:design_template_type_id' });
          this.route('privacy-policy');
          this.route('rules');
          this.route('terms-of-service');
        });
        this.route('design-tokens');
      });

      this.route('promotions-feed', function () {
        this.route('configure');
        this.route('promotions');
      });
      this.route('double-optin-confirmation');
      this.route('performance-report');
    });
  });

  this.route('reset-password');

  this.route('users', function () {
    this.route('user', { path: ':user_id' });
    this.route('new');
  });
});
