import { action } from '@ember/object';
import PromotionAdsController from 'partner/-base/promotion-ads-controller';
export default class SetupAdsController extends PromotionAdsController {
  get columns() {
    return [
      ...this.baseColumns,
      { label: 'Ballot Area', sortable: 'ballotArea', width: 'minmax(min-content, 1fr)' },
      { label: 'Placement', sortable: 'targetEntity.name', width: 'minmax(min-content, 1fr)' },
      { label: '', width: 'minmax(min-content, 1fr)' },
    ];
  }

  get hasGroups() {
    return this.model.matchupGroups.length > 0;
  }

  get hasCategories() {
    return this.model.matchups.length > 0 && !this.model.matchups.firstObject?.isBallotDefaultMatchup;
  }

  get sampleImportFileName() {
    return `Second Street ${
      this.hasGroups ? 'Group' : this.hasCategories ? 'Category' : 'Simple'
    } Ballot Ads Import Template.xlsx`;
  }

  get sampleImportFileLink() {
    return `/media/final/resources/importfiles/SecondStreet_BallotAds_${
      this.hasGroups ? 'Group' : this.hasCategories ? 'Category' : 'Simple'
    }Template.xlsx`;
  }

  @action
  refreshAds() {
    this.send('reload');
  }
}
