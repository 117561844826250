/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import AppContextRoute from 'partner/mixins/app-context-route';

export default Route.extend(AppContextRoute, {
  //region Dependencies
  enums: service(),
  store: service(),
  //endregion

  //region Ember Hooks
  model(params, transition) {
    const designTemplateTypeId = parseInt(params.design_template_type_id, 10);
    const designTemplateType = this.enums.findWhere('TEMPLATE_TYPE', { id: designTemplateTypeId }, 'name');
    const { organization } = this.modelFor('organizations.organization');
    const existingDesignTemplatesOfType = this.modelFor('organizations.organization.legal.design-templates')[
      `${camelize(designTemplateType)}Templates`
    ];

    let sourceTemplate;
    if (transition?.to?.queryParams?.sourceTemplateId) {
      sourceTemplate = this.store.peekRecord('design-template', transition?.to?.queryParams?.sourceTemplateId);
    }

    const newDesignTemplate = sourceTemplate
      ? this.store.createRecord('design-template', {
          designTemplateTypeId,
          organization,
          useDefaultContent: false,
          promotionTypeIds: [],
          designTemplatePromotionTypes: [],
          sourceDesignTemplateId: transition?.to?.queryParams?.sourceTemplateId,
          isLocked: sourceTemplate.isLocked,
        })
      : this.store.createRecord('design-template', {
          designTemplateTypeId,
          organization,
          useDefaultContent: true,
          promotionTypeIds: [],
        });

    return {
      newDesignTemplate,
      existingDesignTemplatesOfType,
    };
  },
  //endregion

  //regin Actions
  actions: {
    willTransition() {
      const { newDesignTemplate, existingDesignTemplatesOfType } = this.modelFor(this.routeName);

      [newDesignTemplate, ...existingDesignTemplatesOfType].forEach(designTemplate => {
        designTemplate.rollbackAttributes();
      });
    },
  },
  //endregion
});
