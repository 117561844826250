/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Renderer from 'isomorphic-template-renderer';
import replaceTokensInPreview from 'partner/utils/replace-tokens-in-preview';

const replaceTokens = replaceTokensInPreview(/{{Organization\.(Name|Address|Timezone)}}/g);

export default Controller.extend({
  //region Ember Hooks
  queryParams: ['isEditing', 'isEditingHandlebars'],
  //endregion

  //region Dependencies
  applicationController: controller('application'),
  organizationController: controller('organizations.organization'),
  enums: service(),
  //endregion

  //region Properties
  isEditing: false,
  // a private Second Street query param accessible only via a URL hack
  // this exposes an interface for us to edit and save Template-type design templates easily and quickly without having to go into the db
  isEditingHandlebars: false,
  //endregion

  lastPage: computed('model.currentDesignTemplate.designTemplateTypeId', function () {
    switch (this.model.currentDesignTemplate.designTemplateTypeId) {
      case this.enums.findWhere('TEMPLATE_TYPE', { name: 'Privacy Policy' }, 'id'):
        return 'organizations.organization.legal.design-templates.privacy-policy';
      case this.enums.findWhere('TEMPLATE_TYPE', { name: 'Rules' }, 'id'):
        return 'organizations.organization.legal.design-templates.rules';
      case this.enums.findWhere('TEMPLATE_TYPE', { name: 'Terms of Service' }, 'id'):
        return 'organizations.organization.legal.design-templates.terms-of-service';
      default:
        return 'organizations.organization.legal.design-templates.privacy-policy';
    }
  }),

  //region Computed Properties
  designTokenData: computed(
    'model.currentDesignTemplate.designTokens.@each.{key,tokenTypeId,tokenContentTypeId,allowMultiple,currentDefaultIsDisabled,currentDefaultValue}',
    function () {
      return this.model.currentDesignTemplate.designTokens.map(designToken => ({
        tokenKey: designToken.key,
        tokenTypeId: designToken.tokenTypeId,
        tokenContentTypeId: designToken.tokenContentTypeId,
        tokenAllowMultiple: designToken.allowMultiple,
        isDisabled: designToken.currentDefaultIsDisabled,
        value: designToken.currentDefaultValue,
      }));
    }
  ),
  renderedContent: computed(
    'model.{currentDesignTemplate.templateContent,dynamicDesignTokens}',
    'designTokenData',
    function () {
      return Renderer.render(
        '',
        this.model.currentDesignTemplate.templateContent,
        this.designTokenData,
        this.model.dynamicDesignTokens.mapBy('key'),
        []
      );
    }
  ),
  tokensToReplace: computed(
    'organizationController.model.organization.{name,timeZoneId}',
    'model.organizationLocation',
    function () {
      const { name, timeZoneId } = this.organizationController.model.organization;
      const { organizationLocation } = this.model;

      return {
        '{{Organization.Name}}': name,
        '{{Organization.Address}}': organizationLocation ? organizationLocation.displayedOrganizationName : '',
        '{{Organization.Timezone}}': this.enums.findWhere('TIME_ZONES', { id: timeZoneId }, 'displayName'),
      };
    }
  ),
  htmlPreview: computed('renderedContent', 'tokensToReplace', function () {
    return replaceTokens(this.renderedContent, this.tokensToReplace);
  }),
  dynamicTokenExclusionList: computed(() => [
    'System.BatchQueueID',
    'System.ReadTracking',
    'System.Stop',
    'Promotion.NameEncoded',
    'Promotion.UrlEncoded',
  ]),
  //endregion

  //region Actions
  actions: {
    closeModal() {
      [this.model.currentDesignTemplate, ...this.model.otherDesignTemplatesOfType].forEach(designTemplate => {
        designTemplate.rollbackAttributes();
      });

      set(this, 'isEditing', false);
    },
  },
  //endregion
});
