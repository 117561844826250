/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { alias, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { PROMO_TYPE_CATEGORY_ROUTE_MAP } from 'partner/utils/constants';

export default Component.extend({
  classNameBindings: [
    'showingPast:past:current-upcoming',
    'archived:archived',
    'archived:ssOrganizationPromotionsListArchived:ssOrganizationPromotionsList',
  ],
  snackbar: service(),

  //region Attributes
  'is-setting-status': false,
  'allow-refresh': true,
  'showing-search': false,
  showCalendar() {},
  //endregion

  //region Computed Properties
  showEmptyMessage: computed('showCommerceProductOnly', 'organizationPromotions.length', function () {
    const count = get(this, 'organizationPromotions.length');
    return (isEmpty(count) || count === 0) && !this.showCommerceProductOnly;
  }),

  emptyListMessage: computed('showingPast', 'archived', 'promotionTypeCategory', 'params.searchValue', {
    get() {
      if (this.params.searchValue) {
        return 'No Results Found.';
      }

      if (this._emptyListMessage) {
        return this._emptyListMessage;
      }

      let itemString;
      switch (this.promotionTypeCategory) {
        case 'contest':
          itemString = 'contests';
          break;
        case 'interactiveContent':
          itemString = 'interactive content';
          break;
        case 'event':
          itemString = 'events';
          break;
        case 'ecommerce':
          itemString = 'ecommerce campaigns';
      }
      if (this.archived) {
        return this.showingPast
          ? `You do not have any past archived ${itemString}.`
          : `You do not have any current or upcoming archived ${itemString}.`;
      }
      return this.showingPast
        ? `You do not have any unarchived past ${itemString}.`
        : `You do not have any current or upcoming ${itemString}.`;
    },

    set(_key, value) {
      return (this._emptyListMessage = value);
    },
  }),
  showArchiveLink: computed('showingPast', 'archived', 'showCommerceProductOnly', function () {
    return !this.showCommerceProductOnly && !this.archived && this.showingPast;
  }),
  archivedRoute: computed('promotionTypeCategory', function () {
    return `organizations.organization.${PROMO_TYPE_CATEGORY_ROUTE_MAP[this.promotionTypeCategory]}.archived`;
  }),
  showFooter: computed('showArchiveLink', 'showPaging', 'organizationPromotions.length', function () {
    return this.showArchiveLink || (this.showPaging && get(this, 'organizationPromotions.length'));
  }),
  activeCount: alias('commerceProductsSummary.activeCount'),
  pastCount: alias('commerceProductsSummary.pastCount'),
  hasPastData: alias('commerceProductsSummary.hasPastData'),
  activeSoldCount: computed('commerceProductsSummary.activeSoldCount', function () {
    const activeSoldCount = get(this, 'commerceProductsSummary.activeSoldCount');
    return activeSoldCount || 0;
  }),
  pastSoldCount: computed('commerceProductsSummary.pastSoldCount', function () {
    const pastSoldCount = get(this, 'commerceProductsSummary.pastSoldCount');
    return pastSoldCount || 0;
  }),
  onPageOne: equal('paging.pageIndex', 1),
  showActiveCommerceProductsSummary: computed(
    'showCommerceProductOnly',
    'commerceProductsSummary',
    'showingPast',
    'onPageOne',
    'commerceProductSummary.loginLink',
    function () {
      return (
        (!this.showingPast && this.showCommerceProductOnly) ||
        (!this.showingPast && this.onPageOne && this.commerceProductsSummary && !this.archived)
      );
    }
  ),
  offersCount: computed('showingPast', 'activeCount', 'pastCount', function () {
    return this.showingPast ? this.pastCount : this.activeCount;
  }),
  offersSoldCount: computed('showingPast', 'activeSoldCount', 'pastSoldCount', function () {
    return this.showingPast ? this.pastSoldCount : this.activeSoldCount;
  }),
  offersType: computed('showingPast', function () {
    return this.showingPast ? 'Past' : 'Active';
  }),
  organizationPromotionsAreEmpty: computed('organizationPromotions.length', function () {
    const length = get(this, 'organizationPromotions.length');
    return length && length > 0 ? false : true;
  }),
  showPaging: computed('paging.{pageSize,totalRecords}', {
    get() {
      if (this._showPaging) {
        return this._showPaging;
      }

      return get(this, 'paging.pageSize') < get(this, 'paging.totalRecords');
    },

    set(_key, value) {
      return (this._showPaging = value);
    },
  }),
  isEcommerce: equal('promotionTypeCategory', 'ecommerce'),
  //endregion

  //region Actions
  actions: {
    changePage(page) {
      set(this, 'paging.pageIndex', page);
    },
    setOrgPromoStatus(orgPromo, status) {
      set(this, 'is-setting-status', true);
      const previousStatusTypeId = get(orgPromo, 'statusTypeId');
      const { paging } = this;
      const pagingKey = this.showingCurrent ? 'current' : 'expired';
      set(orgPromo, 'statusTypeId', status);
      orgPromo.save().then(() => {
        if (!this['showing-search']) {
          let message;
          switch (status) {
            case 1:
              if (orgPromo.isOldPlatform || previousStatusTypeId == 2) {
                message = 'This campaign has been made active.';
              } else {
                message = 'This campaign has been added to the promotions feed.';
              }
              break;
            case 2:
              message = 'Campaign archived. Phew, it always feels nice to tidy up a bit.';
              break;
            case 5:
              if (orgPromo.isOldPlatform) {
                message = 'This campaign is now in test mode.';
              } else {
                message = 'This campaign was removed from the promotions feed.';
              }
              break;
          }
          this.snackbar.show(message, 'Undo').then(() => {
            this['revert-org-promo-status'](orgPromo, previousStatusTypeId, pagingKey);
          });
        }
        if (this.archived) {
          if (status === 1 || status === 5) {
            if (this['allow-refresh']) {
              set(paging, 'refresh', true);
            }
          }
        } else {
          if (status === 2) {
            if (this['allow-refresh']) {
              set(paging, 'refresh', true);
            }
          }
        }
      });
    },
    showCalendar() {
      this.showCalendar();
    },
  },
  //endregion
});
