/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, get, getProperties } from '@ember/object';
import { alias, equal, not } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';
import Copyable from 'partner/mixins/copyable-model';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

const TOKEN_KEYS_WITH_GOOD_DEFAULTS = ['signupHeader', 'signupSubheader', 'signupLabel', 'signupButtonText'];
const TOKEN_CONTENT_TYPES_WITH_GOOD_DEFAULTS = ['Color', 'Code', 'GalleryLayout'];
const IMAGE_TOKEN_CONTENT_TYPES = ['Image', 'LinkableImage', 'ImageWithoutAltText', 'LinkableImageWithoutAltText'];
const TYPES_THAT_REQUIRE_LINK_OR_VALUE = ['TextOrRedirectUrl'];

/**
 * @typedef {DS.Model} DesignTokenContent
 * @implements DesignTokenContentModel
 */
export default BaseModel.extend(Copyable, {
  //region Attributes
  isPlaceholder: attr('boolean'),
  isDisabled: attr('boolean'),
  value: attr('string'),
  linkUrl: attr('string'),
  altText: attr('string'),
  title: attr('string'),
  mediaItemId: attr('number'),
  fieldId: attr('number'),
  isInherited: attr('boolean'),
  sourceTypeId: attr('number'),
  sourceTypeChartId: attr('number'),
  suggestedChartType: attr('string'),
  designTokenId: attr('number'),
  //endregion

  //region Relationships
  organization: belongsTo('organization'),
  design: belongsTo('design', { async: false }),
  designToken: belongsTo('design-token', {
    async: false,
    inverse: 'designTokenContents',
  }),
  //endregion

  //region Computed Properties
  token: alias('designToken'),
  tokenCategory: alias('token.category'),
  isIncomplete: not('isComplete'),
  isEnabled: not('isDisabled'),
  isComplete: computed(
    'value',
    'linkUrl',
    'isEnabled',
    'mediaItemId',
    'altText',
    'isPlaceholder',
    'token.{tokenContentType,key}',
    'isDashboardShareToken',
    function () {
      const tokenContentType = get(this, 'token.tokenContentType');
      const { value } = this;
      const { mediaItemId } = this;

      const isImageComplete =
        IMAGE_TOKEN_CONTENT_TYPES.includes(tokenContentType) &&
        (!isEmpty(value) ||
          (mediaItemId && mediaItemId !== get(this, 'token.tokenDefaultContents.firstObject.mediaItemId')));

      if (this.design.isDashboardShare) {
        return false;
      }
      if (TYPES_THAT_REQUIRE_LINK_OR_VALUE.includes(tokenContentType)) {
        return !isEmpty(value) || !isEmpty(this.linkUrl);
      }
      if (
        !this.isEnabled ||
        TOKEN_CONTENT_TYPES_WITH_GOOD_DEFAULTS.includes(tokenContentType) ||
        (TOKEN_KEYS_WITH_GOOD_DEFAULTS.includes(get(this, 'token.key')) && !isEmpty(value)) ||
        isImageComplete ||
        this.token.key === 'productButtonText'
      ) {
        return true;
      }

      const attrs = ['value', 'linkUrl', 'mediaItemId', 'altText', 'title'];
      const defaultValues = getProperties(get(this, 'token.tokenDefaultContents.firstObject'), ...attrs);
      const currentValues = getProperties(this, ...attrs);
      const defaults = Object.keys(getProperties(get(this, 'token.tokenDefaultContents.firstObject'), ...attrs)).map(
        key => defaultValues[key]
      );
      const current = Object.keys(getProperties(this, ...attrs)).map(key => currentValues[key]);

      return current.every((currentValue, i) => {
        const defaultValue = defaults[i];
        return isEmpty(defaultValue) ? true : currentValue && currentValue !== defaultValue;
      });
    }
  ),
  isValidForInviteEmailContent: computed(
    'value',
    'token.{key,tokenDefaultContents.firstObject.value}',
    'isComplete',
    function () {
      if (get(this, 'token.key') === 'primaryColor') {
        return `${this.value}` !== get(this, 'token.tokenDefaultContents.firstObject.value');
      }
      return this.isComplete;
    }
  ),
  isDashboardShareToken: equal('design.designTemplate.designTemplateType', 'Dashboard Share'),
  //endregion
});
