/* global Redactor */
/* eslint-disable no-control-regex */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { endpoint } from 'secondstreet-common/utils/url';
import { getOwner } from '@ember/application';
import Coloris from '@melloware/coloris';
import CodeMirror from 'codemirror';
import MediaItemModel from '../models/media-item';
import SessionService from 'partner/services/-private/session';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'partner/utils/plugins/redactor/alignment';
import 'partner/utils/plugins/redactor/clearformat';
import 'partner/utils/plugins/redactor/fontsize';
import 'partner/utils/plugins/redactor/fontfamily';
import 'partner/utils/plugins/redactor/fontcolor';
import 'partner/utils/plugins/redactor/images';
import 'partner/utils/plugins/redactor/imagealign';
import 'partner/utils/plugins/redactor/indent';
import 'partner/utils/plugins/redactor/tokens';
import 'partner/utils/plugins/redactor/fontspacing';

interface SsWysiwygSignature {
  Args: {
    simple: boolean;
    isEmail: boolean;
    disabled: boolean;
    filterLinks: boolean;
    isMessageCampaign: boolean;
    disableImageLibrary: boolean;
    hiddenButtons: string[];
    disabledPlugins: string[];
    hiddenAddBarOptions: string[];
    allowEditHTML: boolean;
    overrideShiftEnter: boolean;
    tokens: string[];
    guid: string;
    content: string;
    minHeight: string;
    maxHeight: string;
    libraryImage: any;
    checkForDynamicTokenFallback: (token: any) => void;
    resetRedactorContent: (html: string) => void;
    openImageLibrary: () => void;
    changed: any;
    onBlur: any;
  };
}

declare global {
  const Redactor: any;
}

export default class SsWysiwygComponent extends Component<SsWysiwygSignature> {
  @service declare session: SessionService;

  redactor: typeof Redactor | null = null;
  simple = this.args.simple || false;
  isEmail = this.args.isEmail || false;
  isMessageCampaign = this.args.isMessageCampaign || false;
  hiddenButtons = this.args.hiddenButtons || [];
  disabledPlugins = this.args.disabledPlugins || [];
  hiddenAddBarOptions = this.args.hiddenAddBarOptions || [];
  eventHandler: null | any = null;
  allowEditHTML = this.args.allowEditHTML ?? true;
  overrideShiftEnter = this.args.overrideShiftEnter ?? true;

  get content() {
    return this.args.content || '';
  }

  get checkForDynamicTokenFallback() {
    return (
      this.args.checkForDynamicTokenFallback ||
      function () {
        return undefined;
      }
    );
  }

  get changed() {
    return (
      this.args.changed ||
      function () {
        return undefined;
      }
    );
  }

  get onBlur() {
    return (
      this.args.onBlur ||
      function () {
        return undefined;
      }
    );
  }

  get disabled() {
    return this.args.disabled || false;
  }

  closeMenus(redactor: typeof Redactor | null) {
    return () => {
      redactor?.dropdown.close();
      redactor?.modal.close();
    };
  }

  changedLibraryImage(_element: any, event: (MediaItemModel | typeof Redactor)[]) {
    event[1].editor.restore();
    event[1].broadcast('images.changedLibraryImage', event[0]);
  }

  formatHref(href: string) {
    if (href && href.indexOf('http') !== 0) {
      return `http://${href}`;
    }

    return href;
  }

  removeIFrames(html: string): string {
    const iframeRegex = /<iframe[^>]*>.*?<\/iframe>/gi;

    if (html.match(iframeRegex)) {
      html = html.replace(iframeRegex, '');
      html = this.removeIFrames(html);
      this.redactor?.editor.setContent({ html });
    }

    return html;
  }

  getInvalidTopLevelNodes() {
    const tags: HTMLElement[] = [];

    this.redactor
      ?.getEditor()
      .get()
      .childNodes.forEach((node: HTMLElement) => {
        if (node.offsetParent && ['B', 'I'].includes(node.tagName)) {
          tags.push(node);
        }
      });

    return tags;
  }

  wrapPastedTags(html: string): string {
    const invalidNodes = this.getInvalidTopLevelNodes();

    if (invalidNodes.length) {
      invalidNodes.forEach((node: HTMLElement) => {
        node.outerHTML = `<p>${node.outerHTML}</p>`;
        html = this.redactor?.editor.getContent();
      });

      html = this.wrapPastedTags(html);
    }

    return html;
  }

  cleanupHtml(html: string) {
    html = html.replace(/<meta\b[^>]*>/gi, '');
    html = html.replace(/<!--[\s\S]*?-->/g, '');
    return html.replace(/[\x00-\x1F\x7F]/g, ' ');
  }

  @action
  setup() {
    this.initializeRedactor();

    // Scrolling moves open menus in an undesirable way, so we close them.
    document.addEventListener('scroll', (this.eventHandler = this.closeMenus(this.redactor)));
  }

  @action
  resetContent() {
    this.redactor?.editor.setContent({ html: this.content });
  }

  @action
  cleanup() {
    this.redactor?.control.close();
    this.redactor?.modal.close();
    Redactor.instances.splice(Redactor.instances.indexOf(this.redactor), 1);
    document.removeEventListener('scroll', this.eventHandler);
  }

  initializeRedactor() {
    const minHeight = this.args.minHeight || '200px';
    const { overrideShiftEnter } = this;

    if (!this.disabled) {
      const changeCallback = (html: string) => {
        // If any link does not have a target, add one and set it to _top
        const links = this.redactor?.editor.getEditor().find('a')?.nodes;
        const listItems = this.redactor?.editor.getEditor().find('li')?.nodes;
        const images = this.redactor?.editor.getEditor().find('img')?.nodes;

        html = this.cleanupHtml(html);

        if (images) {
          images.forEach((image: HTMLImageElement) => {
            image.style.maxWidth = '100%';
          });
        }

        if (listItems && !this.isEmail) {
          listItems.forEach((item: HTMLLIElement) => {
            const child: any = item.firstChild;
            const color: string = child?.style?.color || '#000';

            item.style.setProperty('--markerColor', color);
          });
        }

        if (links) {
          links.forEach((link: HTMLAnchorElement) => {
            if (link.parentElement?.style.color) {
              link.style.color = 'inherit';
            }

            if (!link.target) {
              link.target = '_top';
            }

            if (!link.innerHTML && !link.innerText) {
              link.remove();
            }
          });
        }

        // if editing an email we want to remove any iframes because they are not supported
        if (this.args.isEmail) html = this.removeIFrames(html);

        // filter the links to add a target=_top because the modal is being rendered and when clicking
        // on a url within the model the origin of the url refuses to render in a small model
        if (this.args.filterLinks) {
          let i;

          while ((i = html.search(/<a(((?!target=).)*)">/)) > -1) {
            i += 2;
            html = `${html.slice(0, i)} target='_top'${html.slice(i)}`;
          }
        }

        if (!this.isDestroyed || !this.isDestroying) {
          if (this.isEmail) {
            if (this.redactor.source.is()) {
              this.redactor.editor.setContent({ html });
            }

            html = this.redactor.editor.getEmail();
            html = html.replace(/<p[^>]*>\s*<\/p>/gi, '<br>');
          }

          this.changed(html);
        }
      };

      let addbar = this.simple
        ? ['text', 'heading', 'line', 'quote']
        : ['text', 'heading', 'link', 'list', 'quote', 'line'];

      if (!this.isEmail && !this.simple) addbar.push('embed');

      addbar = addbar.filter(option => !this.hiddenAddBarOptions.includes(option));

      const hiddenToolbarButtons = this.simple
        ? ['deleted', 'moreinline', 'embed', 'table', 'image', 'list', 'emoji']
        : ['deleted', 'moreinline', 'table', 'list', 'emoji'];

      const plugins = this.simple
        ? ['emoji', 'clearformat']
        : [
            'alignment',
            'clearformat',
            'blockborder',
            'blockspacing',
            'emoji',
            'fontfamily',
            'fontcolor',
            'fontsize',
            'imageresize',
            'imagealign',
            'indent',
            'tokens',
            'images',
            'fontspacing',
          ];

      // Default Embed description text is a little confusing so we change it.
      Redactor.lang.en.embed.description = 'Paste any embed/html code or enter a youtube or vimeo url';

      Redactor.lang.en.table.nowrap = 'No wrap';

      Redactor.lang.en.hotkeys['meta-shift-7'] = 'Numbered List';
      Redactor.lang.en.hotkeys['meta-shift-8'] = 'Bullet List';

      Redactor.add('block', 'text', {
        mixins: ['block'],
        props: {
          type: 'text',
          editable: true,
          inline: false,
          control: {
            format: {
              position: { after: 'add', first: true },
            },
          },
        },
        defaults: {
          content: { getter: 'getContent', setter: 'setContent' },
        },
        create() {
          const markup: string = this.opts.get('markup');
          return this.params.table
            ? this.dom('<div data-rx-tag="tbr">')
            : this.opts.is('breakline')
            ? this.dom('<div data-rx-tag="br">')
            : this.dom(`<${markup}>`);
        },
        handleEnter(e: any) {
          e.preventDefault();

          let clone;

          if (this.isEmpty() || this.isCaretEnd()) {
            clone = this.app.block._createNewBlockForEnter();
            this.insert({
              instance: clone,
              position: 'after',
              caret: 'start',
              remove: false,
              type: 'input',
            });
          } else if (this.isCaretStart()) {
            clone = this.duplicateEmpty();
            this.insert({ instance: clone, position: 'before', type: 'input' });
          } else {
            const elm = this.app.create('element');
            const $block = this.getBlock();
            const $part = elm.split($block);

            this.app.block.set($part, 'start');
          }

          return true;
        },
      });

      Coloris.init();

      const redactorSelector = this.args.guid ? `#redactor-entry-${this.args.guid}` : '#redactor-entry';

      this.redactor = Redactor(redactorSelector, {
        subscribe: {
          'source.change': (event: any) => changeCallback(event.params.e.getValue()),
          'editor.change': (event: any) => changeCallback(event.params.html),
          'editor.blur': () => this.onBlur(),
          'editor.before.paste': (event: any) => {
            event.set('html', this.cleanupHtml(event.get('html')));
          },
          'editor.paste': (event: any) => {
            this.redactor?.editor.insertContent({ html: this.wrapPastedTags(event.params.html()) });
          },
          'modal.open': (event: any) => {
            // Add a button so Partners can test links
            if (this.redactor?.modal.name === 'link') {
              const linkTestButton = document.createElement('a');
              const linkTestIcon = document.createElement('i');
              const linkTestIconText = document.createTextNode('open_in_new');

              linkTestButton.className = 'block absolute bg-white hover:bg-gray-100 text-center rounded w-6 h-6';
              linkTestIcon.className = 'relative material-icons text-gray-800 text-base';
              linkTestButton.style.visibility = event.params.stack.data.url ? 'visible' : 'hidden';
              linkTestButton.style.right = '24px';
              linkTestButton.style.top = '184px';
              linkTestButton.target = '_blank';
              linkTestButton.href = event.params.stack.data.url;

              linkTestIcon.appendChild(linkTestIconText);
              linkTestButton.append(linkTestIcon);
              event.params.stack.getStack().append(linkTestButton);

              event.params.stack
                .getStack()
                .find('input[name="url"]')
                .get()
                .addEventListener('input', (event: any) => {
                  linkTestButton.style.visibility = event.target.value ? 'visible' : 'hidden';
                  linkTestButton.href = this.formatHref(event.target.value);
                });

              // Set the default checkbox value based on existing link target
              if (event.params.stack.data.url) {
                const { savedSelection } = this.redactor.editor;
                this.redactor.editor.restore();

                const selection = this.redactor.create('selection');
                const $link: HTMLAnchorElement | undefined = selection.getNodes({
                  tags: ['a'],
                }).firstObject;

                if ($link) {
                  const checkbox = event.params.stack.getStack().find('input[name="target"]').get();
                  checkbox.checked = $link.target === '_blank';
                }

                this.redactor.editor.savedSelection = savedSelection;
              }
            }
          },
          'link.add': (event: any) => {
            const $link = event.get('element');

            $link.addClass('underline');
            $link.get().href = this.formatHref(event.params.data.url);

            // Links added within underlines break,
            // so we build the link here instead
            this.redactor.editor.restore();

            const selection = this.redactor.create('selection');
            const insertion = this.redactor.create('insertion');

            const nodes = selection.getNodes({ tags: ['a'] });

            nodes.lastObject.remove();
            insertion.insertHtml($link.get().outerHTML);
          },
          'link.edit': (event: any) => {
            event.params.element.get().href = this.formatHref(event.params.data.url);
          },
          'upload.before.send': (event: any) => {
            const xhr: XMLHttpRequest = event.get('xhr');
            const { headers }: any = getOwner(this)?.lookup('adapter:application');

            Object.keys(headers).forEach(key => {
              xhr.setRequestHeader(key, headers[key]);
            });
          },
          'tokens.changeDynamicTokenContent': (event: any) => this.checkForDynamicTokenFallback(event.params),
          'images.searchImage': () => {
            // Selecting tabs in the image library defocuses the editor, so we save the cursor position
            this.redactor.editor.save();

            this.args.openImageLibrary();
          },
        },
        theme: 'light',
        paste: {
          clean: false,
        },
        clean: {
          enterinline: false,
          enter: false,
        },
        hotkeysRemove: ['ctrl+h, meta+h', 'ctrl+k, meta+k', 'ctrl+l, meta+l', 'ctrl+shift+m, meta+shift+m'],
        minHeight,
        maxHeight: this.args.maxHeight || false,
        plugins: plugins.filter(plugin => !this.disabledPlugins.includes(plugin)),
        grammarly: true,
        codemirror: {
          mode: 'htmlmixed',
          theme: 'material',
          lineNumbers: true,
          lineWrapping: true,
        },
        codemirrorSrc: CodeMirror,
        link: {
          target: '_blank',
        },
        toolbar: {
          hide: hiddenToolbarButtons.concat(this.hiddenButtons),
        },
        popups: {
          format: ['h1', 'h2', 'h3', 'h4', 'quote', 'bulletlist', 'numberedlist'],
          addbar,
        },
        tokens: {
          tokens: this.args.tokens || [],
        },
        images: {
          uploadRoute: endpoint('media_items'),
          disableLibrary: this.args.disableImageLibrary || false,
          isMessageCampaign: this.isMessageCampaign,
        },
        image: {
          width: true,
          tag: 'div',
        },
        fontfamily: {
          items: this.isEmail ? ['Arial', 'Helvetica', 'Georgia', 'Times New Roman', 'Monospace'] : false,
        },
      });

      this.redactor.hotkeys.add('ctrl+], meta+]', {
        title: 'Indent',
        name: 'meta+]',
        command: 'indent.indent',
      });

      this.redactor.hotkeys.add('ctrl+[, meta+[', {
        title: 'Outdent',
        name: 'meta+[',
        command: 'indent.outdent',
      });

      if (!this.disabledPlugins.includes('emoji') && !this.hiddenAddBarOptions.includes('emoji')) {
        // Emoji default plugin overrides
        this.redactor.hotkeys.add('ctrl+e, meta+e', {
          title: 'Emoji',
          name: 'meta+e',
          command: 'emoji.popup',
        });

        this.redactor.addbar.add('emoji', {
          title: 'Emoji',
          position: { after: 'text' },
          icon: this.redactor.opts.get('emoji.icon'),
          command: 'emoji.popup',
        });
      }

      if (this.allowEditHTML) {
        // Add source button to control menus
        this.redactor.control.add('html', {
          title: 'HTML',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.9701 4.24253C15.1041 3.70673 14.7783 3.1638 14.2425 3.02985C13.7067 2.8959 13.1638 3.22166 13.0299 3.75746L9.02986 19.7575C8.89591 20.2933 9.22167 20.8362 9.75746 20.9701C10.2933 21.1041 10.8362 20.7783 10.9701 20.2425L14.9701 4.24253ZM7.70711 7.29289C8.09763 7.68341 8.09763 8.31658 7.70711 8.7071L4.41421 12L7.70711 15.2929C8.09763 15.6834 8.09763 16.3166 7.70711 16.7071C7.31658 17.0976 6.68342 17.0976 6.29289 16.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L6.29289 7.29289C6.68342 6.90236 7.31658 6.90236 7.70711 7.29289ZM16.2929 7.29289C16.6834 6.90236 17.3166 6.90236 17.7071 7.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L17.7071 16.7071C17.3166 17.0976 16.6834 17.0976 16.2929 16.7071C15.9024 16.3166 15.9024 15.6834 16.2929 15.2929L19.5858 12L16.2929 8.7071C15.9024 8.31658 15.9024 7.68341 16.2929 7.29289Z"/></svg>',
          command: 'source.toggle',
          position: {
            before: 'indent',
          },
        });

        this.redactor.hotkeys.add('ctrl+shift+h, meta+shift+h', {
          title: 'Show HTML',
          name: 'meta+shift+h',
          command: 'source.toggle',
        });
      }

      this.redactor.hotkeys.add('ctrl+l, meta+l', {
        title: 'Link',
        name: 'meta+l',
        command: 'link.popup',
      });

      // Override HTML button toggle so is hides in the editor, and shows in the html view
      this.redactor.source.toggle = () => {
        if (this.redactor.source.is()) {
          this.redactor.source.close();
          this.redactor.toolbar.remove('html');
          this.redactor.toolbar._buildButtons();
          return;
        }

        this.redactor.toolbar.removeButtons = this.redactor.toolbar.removeButtons.filter(
          (button: any) => button !== 'html'
        );
        this.redactor.toolbar._buildButtons();
        this.redactor.source.open();
      };

      this.redactor.toolbar.remove('html');
      this.redactor.toolbar._buildButtons();

      // Overriding to fix Redactor showing incorrect titles
      this.redactor.modal._renderHeader = () => {
        const title = this.redactor.modal.stack.getTitle();

        if (title) {
          this.redactor.modal.$header.html(this.redactor.lang.parse(title));
          this.redactor.modal._buildClose();
          return;
        }

        this.redactor.modal.$header.html('');
      };

      this.redactor.link.save = function (stack: any) {
        this.app.modal.close();

        const $link = this.app.link.get();

        this.app.editor.save();

        const data = this._setData(stack.getData(), $link);

        this.app.editor.restore();
        this.app.observer.observe();

        this.app.broadcast('link.change', { element: $link, data });
      };

      this.redactor.link._setTarget = function ($link: any, data: any) {
        if (data.target) {
          $link.attr('target', '_blank');
          return data;
        }

        $link.attr('target', '_top');

        return data;
      };

      this.redactor.control.removeButtons = this.simple
        ? ['text', 'wrap', 'outset', 'border', 'spacing']
        : ['text', 'wrap', 'outset'];

      this.redactor.opts.set('buttonsObj.link.position', { before: 'bold' });
      this.redactor.opts.set('buttonsObj.link.blocks', { all: true, except: ['image'] });

      this.redactor.opts.set('popups.format', [
        'h1',
        'h2',
        'h3',
        'h4',
        'bulletlist',
        'numberedlist',
        {
          title: '<u>Underline</u>',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C7.55228 4 8 4.44772 8 5V10C8 11.0609 8.42143 12.0783 9.17157 12.8284C9.92172 13.5786 10.9391 14 12 14C13.0609 14 14.0783 13.5786 14.8284 12.8284C15.5786 12.0783 16 11.0609 16 10V5C16 4.44772 16.4477 4 17 4C17.5523 4 18 4.44772 18 5V10C18 11.5913 17.3679 13.1174 16.2426 14.2426C15.1174 15.3679 13.5913 16 12 16C10.4087 16 8.88258 15.3679 7.75736 14.2426C6.63214 13.1174 6 11.5913 6 10V5C6 4.44772 6.44772 4 7 4ZM4 19C4 18.4477 4.44772 18 5 18H19C19.5523 18 20 18.4477 20 19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19Z"/></svg>',
          type: 'underline',
          command: 'inline.set',
          params: {
            tag: 'u',
          },
        },
        {
          title: 'Superscript',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.9565 3.09068C18.7281 2.88025 19.5517 2.98495 20.2461 3.38175C20.5899 3.57822 20.8917 3.8405 21.1342 4.1536C21.3767 4.4667 21.5551 4.82449 21.6593 5.20655C21.7635 5.5886 21.7914 5.98744 21.7415 6.38029C21.6915 6.77313 21.5647 7.1523 21.3682 7.49613C21.3352 7.55397 21.2964 7.60836 21.2526 7.6585L19.2037 9.99999H21C21.5523 9.99999 22 10.4477 22 11C22 11.5523 21.5523 12 21 12H17C16.6076 12 16.2515 11.7705 16.0893 11.4132C15.9272 11.0559 15.989 10.6368 16.2474 10.3415L19.6701 6.42985C19.7146 6.33455 19.7441 6.23275 19.7574 6.12807C19.7743 5.99576 19.7648 5.86144 19.7298 5.73278C19.6947 5.60411 19.6346 5.48362 19.5529 5.37818C19.4713 5.27273 19.3696 5.1844 19.2538 5.11824C19.02 4.9846 18.7426 4.94934 18.4828 5.02021C18.2229 5.09108 18.0019 5.26227 17.8682 5.49613C17.5942 5.97565 16.9834 6.14225 16.5038 5.86824C16.0243 5.59423 15.8577 4.98337 16.1317 4.50385C16.5285 3.80945 17.1849 3.30112 17.9565 3.09068ZM4.37528 6.21913C4.80654 5.87412 5.43584 5.94404 5.78084 6.3753L8.99998 10.3992L12.2191 6.3753C12.5641 5.94404 13.1934 5.87412 13.6247 6.21913C14.0559 6.56414 14.1259 7.19343 13.7808 7.62469L10.2806 12L13.7808 16.3753C14.1259 16.8066 14.0559 17.4359 13.6247 17.7809C13.1934 18.1259 12.5641 18.056 12.2191 17.6247L8.99998 13.6008L5.78084 17.6247C5.43584 18.056 4.80654 18.1259 4.37528 17.7809C3.94402 17.4359 3.8741 16.8066 4.21911 16.3753L7.71935 12L4.21911 7.62469C3.8741 7.19343 3.94402 6.56414 4.37528 6.21913Z"/></svg>',
          command: 'inline.set',
          params: { tag: 'sup' },
        },
        {
          title: 'Subscript',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.37528 6.21914C4.80654 5.87413 5.43584 5.94405 5.78084 6.37531L8.99998 10.3992L12.2191 6.37531C12.5641 5.94405 13.1934 5.87413 13.6247 6.21914C14.0559 6.56415 14.1259 7.19344 13.7808 7.6247L10.2806 12L13.7808 16.3753C14.1259 16.8066 14.0559 17.4359 13.6247 17.7809C13.1934 18.1259 12.5641 18.056 12.2191 17.6247L8.99998 13.6008L5.78084 17.6247C5.43584 18.056 4.80654 18.1259 4.37528 17.7809C3.94402 17.4359 3.8741 16.8066 4.21911 16.3753L7.71935 12L4.21911 7.6247C3.8741 7.19344 3.94402 6.56415 4.37528 6.21914ZM17.9565 12.0907C18.3386 11.9865 18.7374 11.9586 19.1303 12.0085C19.5231 12.0585 19.9023 12.1853 20.2461 12.3818C20.5899 12.5782 20.8917 12.8405 21.1342 13.1536C21.3767 13.4667 21.5551 13.8245 21.6593 14.2066C21.7635 14.5886 21.7914 14.9875 21.7415 15.3803C21.6915 15.7731 21.5647 16.1523 21.3682 16.4961C21.3352 16.554 21.2964 16.6084 21.2526 16.6585L19.2037 19H21C21.5523 19 22 19.4477 22 20C22 20.5523 21.5523 21 21 21H17C16.6076 21 16.2515 20.7705 16.0893 20.4132C15.9272 20.0559 15.989 19.6368 16.2474 19.3415L19.6701 15.4299C19.7146 15.3346 19.7441 15.2328 19.7574 15.1281C19.7743 14.9958 19.7648 14.8615 19.7298 14.7328C19.6947 14.6041 19.6346 14.4836 19.5529 14.3782C19.4713 14.2727 19.3696 14.1844 19.2538 14.1182C19.138 14.0521 19.0104 14.0094 18.878 13.9926C18.7457 13.9757 18.6114 13.9851 18.4828 14.0202C18.3541 14.0553 18.2336 14.1154 18.1282 14.1971C18.0227 14.2787 17.9344 14.3804 17.8682 14.4961C17.5942 14.9757 16.9834 15.1423 16.5038 14.8682C16.0243 14.5942 15.8577 13.9834 16.1317 13.5039C16.3282 13.16 16.5905 12.8583 16.9036 12.6158C17.2167 12.3733 17.5745 12.1949 17.9565 12.0907Z"/></svg>',
          command: 'inline.set',
          params: { tag: 'sub' },
        },
        {
          title: '<span class="text-red-500">Clean Format</span>',
          icon: '<i class="material-icons-outlined text-red-500 text-lg">cleaning_services</i>',
          command: 'clearformat.clean',
        },
      ]);

      // Default image module override
      this.redactor.opts.set('buttonsObj.image.title', 'Edit Image');
      this.redactor.image.save = (stack: any) => {
        const data = stack.getData();

        if (data.url) {
          data.url = this.formatHref(data.url);
        }

        this.redactor.modal.close();
        this.redactor.block.setData(data);
      };
      this.redactor.image.observe = (obj: any) => {
        const instance = this.redactor.block.get();

        if (instance && instance.isType('image')) {
          obj.command = 'image.edit';
          return obj;
        }

        return;
      };
      this.redactor.toolbar._buildButtons();

      this.redactor.block.trigger = function (mutation: any) {
        if (!this.is()) return;
        if (this.instance.isEditable() && this.instance.isEmpty()) {
          // Stop Redactor from cleaning new blocks if it is cloned
          if (!mutation.target.getAttribute('data-rx-cloned')) {
            this.instance.setEmpty();
          }
        }

        if (mutation.type === 'childlist' || mutation.type === 'characterData') {
          this.instance.trigger(mutation);
        }
      };

      // Override Redactor handle function so we can swap enter/shift enter
      this.redactor.input.handle = function (event: any) {
        const e = event.get('e');
        const key = event.get('key');
        const blockName = this.app.block.get()._name;

        if (this._doSelectAll(e, event)) {
          return;
        }

        // typing
        if (event.is(['enter', 'delete', 'backspace', 'alpha', 'space'])) {
          this.app.control.updatePosition();
        }

        // events
        if (event.is('enter') && event.is('shift')) {
          blockName === 'listitem' || !overrideShiftEnter
            ? this.handleShiftEnter(e, key, event)
            : this.handleEnter(e, key, event);
        } else if (event.is('enter')) {
          blockName === 'listitem' || !overrideShiftEnter
            ? this.handleEnter(e, key, event)
            : this.handleShiftEnter(e, key, event);
        } else if (event.is('space') && event.is('shift')) {
          this.handleShiftSpace(e, key, event);
        } else if (event.is('space')) {
          this.handleSpace(e, key, event);
        } else if (event.is('tab') && this.opts.is('tab.key')) {
          this.handleTab(e, key, event);
        } else if (event.is('arrow')) {
          if (event.is('ctrl') && event.is('up')) {
            this.handleArrowCtrl(e, key, event);
            return;
          }
          if (event.is(['shift', 'alt', 'ctrl'])) return;

          this.handleArrow(e, key, event);
        } else if (event.is(['delete', 'backspace'])) {
          this.handleDelete(e, key, event);
        }
      };

      this.redactor.block._createNewBlockForEnter = function () {
        let newBlock =
          this.$block.attr('data-rx-tag') === 'tbr'
            ? this.app.create('block.text', { table: true })
            : this.app.block.create();

        newBlock.getBlock().attr('data-rx-first-level', true);

        if (!this.opts.is('clean.enter')) {
          newBlock.getBlock().removeAttr('id');
        }

        if (!this.opts.is('clean.enterinline') && !this.app.block.get().isEmpty()) {
          newBlock = this._cloneInline(newBlock);
        }

        return newBlock;
      };

      this.redactor.block._cloneInline = function (newBlock: any) {
        const block = this.$block;

        this._cloneChildren(newBlock.getBlock().get(), block.get());
        this._cloneStyle(block.get(), newBlock.getBlock().get());

        return newBlock;
      };

      this.redactor.block._cloneChildren = function (
        newParent: HTMLElement,
        oldParent: HTMLElement,
        firstLevel: HTMLElement | null = null
      ) {
        if (newParent.hasAttribute('data-rx-first-level')) firstLevel = newParent;

        const allowedTags = ['SPAN', 'U', 'B', 'I'];
        const children = Array.from(oldParent.children).filter((child: any) => {
          return allowedTags.includes(child.tagName);
        });

        if (children.length !== 1) {
          // If lowest level child has content, mark top level as copied
          if (children.length === 0 && oldParent.innerHTML) {
            firstLevel?.setAttribute('data-rx-cloned', 'true');
          }

          return newParent;
        }

        const childElement = children.firstObject;

        if (!childElement?.tagName) return newParent;

        let newChild = document.createElement(childElement.tagName);

        newChild = this._cloneChildren(newChild, childElement, firstLevel);
        newChild = this._cloneStyle(childElement, newChild);

        newParent.innerHTML = newChild.outerHTML;

        return newParent;
      };

      this.redactor.block._cloneStyle = function (element: HTMLElement, target: HTMLElement) {
        target.style.color = element.style.color;
        target.style.background = element.style.background;
        target.style.backgroundColor = element.style.backgroundColor;
        target.style.fontSize = element.style.fontSize;
        target.style.fontFamily = element.style.fontFamily;
        target.style.textAlign = element.style.textAlign;
        target.style.lineHeight = element.style.lineHeight;
        target.style.letterSpacing = element.style.letterSpacing;

        return target;
      };

      this.redactor.block.add = function (_e: any, button: any, name: string) {
        // popup
        this.app.dropdown.close();
        this.app.modal.close();

        const insertion = this.app.create('insertion');
        const template = button.getTemplate();
        const position = 'after';
        let newInstance, inserted;

        if (template) {
          inserted = insertion.insert({ html: template, position });
        } else {
          newInstance = this.app.create(`block.${name}`);

          if (name === 'text' && this.$block.attr('data-rx-type') === 'text') {
            newInstance = this._createNewBlockForEnter();
          }

          inserted = insertion.insert({ instance: newInstance, position, type: 'add' });
        }

        this.app.broadcast('block.add', { inserted });

        return inserted;
      };

      // enter key is not moving caret, so we override the function to do it
      this.redactor.input.handleShiftEnter = function (e: any) {
        const instance = this.app.block.get();
        const insertion = this.app.create('insertion');
        const selection = this.app.create('selection');
        const caret = this.app.create('caret');

        // multiple selection
        if (this.app.blocks.is()) {
          e.preventDefault();

          const $first = this.app.blocks.get({ first: true, selected: true });

          selection.truncate();
          caret.set($first, 'end');
          insertion.insertBreakline();

          return;
        }

        // inside selection
        if (this._deleteInsideSelection(e)) {
          return;
        }

        // inline block
        if (instance.isInline()) {
          e.preventDefault();

          if (!instance.isEditable()) {
            caret.set(instance.getBlock(), 'after');
            instance.remove();
          }

          return;
        }
        // editable
        else if (instance.isEditable()) {
          e.preventDefault();

          insertion.insertBreakline();
        }
        // non editable
        else {
          e.preventDefault();

          instance.insertEmpty({ position: 'after', caret: 'start', type: 'input' });
        }
      };

      if (this.simple) {
        this.redactor.hotkeys.remove('ctrl+shift+7, meta+shift+7');
        this.redactor.hotkeys.remove('ctrl+shift+8, meta+shift+8');
        this.redactor.hotkeys.remove('ctrl+shift+h, meta+shift+h');
        this.redactor.hotkeys.remove('ctrl+[, meta+[');
        this.redactor.hotkeys.remove('ctrl+], meta+]');
        this.redactor.hotkeys.init();
      }

      // Sometimes the hidden textarea takes over focus, so we disable it
      this.redactor.element.get().disabled = true;

      // Redactor is caching previous instances, so we need to remove any undefined instances
      Redactor.instances = Redactor.instances.filter(Boolean);
    }
  }
}
