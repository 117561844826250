/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import RSVP from 'rsvp';

export default Component.extend({
  //region Dependencies
  enums: service(),
  //endregion

  //region Attributes
  currentDesignTemplate: null,
  otherDesignTemplatesOfType: null,
  hasPromotions: false,
  hasInteractiveContent: false,
  cancel() {},
  finishSave() {},
  //endregion

  //region Properties
  isAnythingSaving: false,
  //endregion

  isAnythingDirty: or('currentDesignTemplate.{isNameDirty,isPromotionTypeIdsDirty,isLockedDirty}'),

  isSaveDisabled: computed('isAnythingSaving', 'isAnythingDirty', 'currentDesignTemplate.name', function () {
    return !this.isAnythingDirty || this.isAnythingSaving || isEmpty(this.currentDesignTemplate.name);
  }),

  nameStatus: computed('currentDesignTemplate.name', function () {
    return isPresent(this.currentDesignTemplate.name) ? 'bestPractice' : 'incomplete';
  }),

  lockedStatus: computed('currentDesignTemplate.isLockedDirty', function () {
    return this.currentDesignTemplate.isNew ? 'incomplete' : 'bestPractice';
  }),

  //endregion

  //region Actions
  actions: {
    async save() {
      set(this, 'isAnythingSaving', true);

      const allDesignTemplates = [this.currentDesignTemplate, ...this.otherDesignTemplatesOfType];

      try {
        await RSVP.all(allDesignTemplates.filterBy('hasDirtyAttributes').map(designTemplate => designTemplate.save()));
        this.finishSave();
      } catch (error) {
        set(this, 'isAnythingSaving', false);
        throw new Error(error);
      }
    },

    cancel() {
      this.cancel();
    },
  },
  //endregion
});
