/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get, observer, set, setProperties } from '@ember/object';
import { alias, and, equal, filterBy, gt, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import moment from 'moment';
import { AD_SIZES } from 'partner/utils/adgagement';
import { AdgagementEmbed, PromotionEmbed } from 'partner/utils/embed-scripts';
import isAnyPath from 'partner/utils/is-any-path';
import isValidUrl from 'partner/utils/is-valid-url';
import { wordpressCodeForPromotions } from 'partner/utils/wordpress-shortcodes';

export default Controller.extend({
  //region Ember Hooks
  facebook: service(),
  setup: controller('organizations.organization.organization-promotions.organization-promotion.setup'),
  snackbar: service(),
  session: service(),
  fullStory: service(),
  enums: service(),
  features: service(),
  store: service(),
  //endregion

  //region Properties
  isAdgagementComplete: false,
  isGettingAdgagementCode: false,
  isShowingEmbedCode: false,
  isShowingWordpressCode: false,
  isAddingFacebookPage: false,
  isPreparingToCopy: null,
  showDeviceAwareInfo: false,
  pageToBeDeleted: null,
  showEmbedUi: equal('model.domainRedirection.statusTypeId', 1),
  isShowingTrustedDomains: false,
  domainRedirectionHasErrors: gt('model.domainRedirection.errors.length', 0),
  isDomainRedirectionValid: false,
  isUsingDefaultWrapperState: false,
  /**
   showEmbedUi: false,
   * The OrganizationPromotionDomain for the Facebook Page we're currently editing
   * @type {OrganizationPromotionDomain?}
   */
  editingPage: null,
  /**
   * Dimensions passed into the data-adgagement attr for adgagement options
   */
  embedFrameDimensions: '250x250',
  adSizeOptions: AD_SIZES,
  //endregion

  //region Computed Properties
  userEmailAddress: readOnly('session.data.authenticated.organization_users.email_address'),
  userCanPublishPromotions: computed(
    'model.promotionApprovers.@each.emailAddress',
    'userEmailAddress',
    'session.data.authenticated.organization_users.user_type_id',
    function () {
      const userTypeId = get(this, 'session.data.authenticated.organization_users.user_type_id');
      const isUserType = type => userTypeId === this.enums.findWhere('USER_TYPE', { name: type }, 'id');
      return (
        get(this, 'model.promotionApprovers').isAny('emailAddress', this.userEmailAddress) ||
        isUserType('SecondStreetAdmin') ||
        isUserType('SuperAdmin')
      );
    }
  ),
  wrappers: alias('model.wrappers'),
  orgDefaultWrappers: alias('model.orgDefaultWrappers'),
  orgDefaultSmallWrapper: computed('orgDefaultWrappers.@each.browserPlatformType', function () {
    return this.orgDefaultWrappers.filterBy('browserPlatformType', 'Mobile')[0];
  }),
  orgDefaultLargeWrapper: computed('orgDefaultWrappers.@each.browserPlatformType', function () {
    return this.orgDefaultWrappers.filterBy('browserPlatformType', 'Desktop')[0];
  }),
  sortedPublishApprovers: computed('model.promotionApprovers.@each.{firstName,lastName,emailAddress}', function () {
    return get(this, 'model.promotionApprovers').sortBy('firstName', 'lastName', 'emailAddress');
  }),
  organizationPromotionLevelWrappers: filterBy('wrappers', 'isOneTimeWrapper'),
  smallDeviceWrapper: computed('wrappers.@each.browserPlatformType', function () {
    return this.organizationPromotionLevelWrappers.filterBy('browserPlatformType', 'Mobile')[0];
  }),
  largeDeviceWrapper: computed('wrappers.@each.browserPlatformType', function () {
    return this.organizationPromotionLevelWrappers.filterBy('browserPlatformType', 'Desktop')[0];
  }),
  wrapperInformation: computed(
    'noWrapperState',
    'usingCustomNoWrapperState',
    'usingDefaultWrapperState',
    'usingOneTimeWrapperState',
    function () {
      if (this.usingOneTimeWrapperState) {
        if (this.usingCustomNoWrapperState) {
          return 'This campaign is not using a wrapper.';
        }
        return 'This campaign is using a one-time wrapper.';
      } else if (this.usingDefaultWrapperState) {
        return 'This page is using your default wrapper.';
      } else if (this.noWrapperState) {
        return 'Want to make this campaign look like a page on your site?';
      }
      return;
    }
  ),
  noWrapperState: isAnyPath('isRootAndInherited', ['wrappers.[]']),
  usingCustomNoWrapperState: and('smallDeviceWrapper.isCustomNoWrapper', 'largeDeviceWrapper.isCustomNoWrapper'),
  usingDefaultWrapperState: computed('isUsingDefault', 'wrappers.[]', 'isUsingOneTimeWrapperState', function () {
    return this.isUsingDefaultWrapperState || isAnyPath('isOneTimeWrapper', ['wrappers.[]']);
  }),
  usingOneTimeWrapperState: isAnyPath('isOneTimeWrapper', ['wrappers.[]']),
  facebookOrganizationPromotionDomains: computed('model.facebookOrganizationPromotionDomains.length', function () {
    return get(this, 'model.facebookOrganizationPromotionDomains');
  }),
  isRedirected: equal('model.domainRedirection.statusType', 'Active'),
  domainRedirectionUrl: computed('model.domainRedirection.redirectUrl', {
    get() {
      return get(this, 'model.domainRedirection.redirectUrl') || '';
    },
    set(key, value) {
      const strValue = `${value ?? ''}`.trim();
      if (get(this, 'model.domainRedirection')) {
        set(this, 'model.domainRedirection.redirectUrl', strValue);
      } else {
        this.createRedirection(strValue);
      }
      return strValue;
    },
  }),
  publishApprover: computed('sortedPublishApprovers.[]', {
    get() {
      return get(this, 'sortedPublishApprovers.firstObject');
    },
    set(key, value) {
      return value;
    },
  }),
  isQuestionOneDirty: isAnyPath('isDirty', [
    'model.domainRedirection',
    'model.organizationPromotionDomain',
    'domains.[]',
  ]),
  isFacebookPublishDirty: isAnyPath('isDirty', ['model.facebookOrganizationPromotionDomains.[]']),
  isPublishApprovalDirty: isAnyPath('hasDirtyAttributes', ['model.promotionApprovalSetting']),
  isAnyStaticIframeSettingDirty: or(
    'model.settings.useStaticIframe.hasDirtyAttributes',
    'model.settings.topOffsetIframe.hasDirtyAttributes',
    'model.settings.bottomOffsetIframe.hasDirtyAttributes'
  ),
  isAnythingDirty: or(
    'isQuestionOneDirty',
    'isFacebookPublishDirty',
    'isPublishApprovalDirty',
    'setup.hasDirtyAttributes',
    'smallDeviceWrapper.hasDirtyAttributes',
    'orgDefaultSmallWrapper.hasDirtyAttributes',
    'largeDeviceWrapper.hasDirtyAttributes',
    'orgDefaultLargeWrapper.hasDirtyAttributes',
    'model.domainRedirection.hasDirtyAttributes',
    'isAnyStaticIframeSettingDirty'
  ),
  /**
   * @returns {String}
   */
  embedCode: computed('model.organizationPromotion.{id,uniqueId}', function () {
    return new PromotionEmbed(this.model.organizationPromotion.id, this.model.organizationPromotion.uniqueId).embedCode;
  }),
  /**
   * @returns {String}
   */
  adgagementCode: computed('model.organizationPromotion.{id,uniqueId}', 'embedFrameDimensions', function () {
    return new AdgagementEmbed(
      this.model.organizationPromotion.id,
      this.model.organizationPromotion.uniqueId,
      this.embedFrameDimensions
    ).embedCode;
  }),
  /**
   * @returns {String}
   */
  wordpressCode: computed('model.organizationPromotion.{id,uniqueId}', function () {
    return wordpressCodeForPromotions(this.model.organizationPromotion.id, this.model.organizationPromotion.uniqueId);
  }),
  isFacebookPublishComplete: computed('facebookOrganizationPromotionDomains.@each.isDeleted', function () {
    const domains = this.facebookOrganizationPromotionDomains.filterBy('isDeleted', false);
    return get(domains, 'length') >= 1;
  }),

  isTopOffsetIframeInvalid: computed('model.settings.topOffsetIframe.value', function () {
    return !/^\d+$/.test(this.model.settings.topOffsetIframe.value);
  }),
  isBottomOffsetIframeInvalid: computed('model.settings.bottomOffsetIframe.value', function () {
    return !/^\d+$/.test(this.model.settings.bottomOffsetIframe.value);
  }),
  //endregion

  //region Methods
  createRedirection(url) {
    const domainRedirection = this.store.createRecord('domain-redirection', {
      name: `Embedded URL for: ${get(this, 'model.organizationPromotion.id')}`,
      path: get(this, 'model.organizationPromotionDomain.rootFolder'),
      domainId: window.parseInt(get(this, 'model.organizationPromotionDomain.domain.id'), 10),
      convertPathToHash: true,
      includePathAndQuery: true,
      isEmbedRedirect: true,
    });
    set(domainRedirection, 'statusType', 'Active');
    if (url) {
      set(domainRedirection, 'redirectUrl', url);
    }
    set(this, 'model.domainRedirection', domainRedirection);
  },
  createCustomWrappersIfNeeded() {
    const { wrappers } = this;
    const newWrapper = browserPlatformType =>
      this.store.createRecord('wrapper', {
        organizationPromotionId: get(this, 'model.organizationPromotion.id'),
        browserPlatformTypeId: enums.findWhere('BROWSER_PLATFORM_TYPE', { name: `${browserPlatformType}` }, 'id'),
        wrapperMethodTypeId: enums.findWhere('WRAPPER_METHOD_TYPE', { name: 'No Wrapper' }, 'id'),
        htmlContent: `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                    <title></title>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                    <link rel="stylesheet" type="text/css" href="/StaticContent/CSS/default_wrapper.css" />
                    </head>
                    <body class="ssDefaultWrapper">
                    {SS-APP}
                    </body>
                    </html>`,
        applicationTokenKey: '{SS-APP}',
        scrapeUrl: '',
      });
    if (!this.largeDeviceWrapper) {
      wrappers.addObject(newWrapper('Desktop'));
    }
    if (!this.smallDeviceWrapper) {
      wrappers.addObject(newWrapper('Mobile'));
    }
  },
  opdForPage(page) {
    return get(this, 'model.facebookOrganizationPromotionDomains').find(x => get(x, 'domain.facebookPage') === page);
  },
  addToFacebookPage(page) {
    const domains = get(this, 'model.domains');

    const domain =
      domains.findBy('externalLocationId', get(page, 'id')) ||
      this.store.createRecord('domain', {
        hostname: `facebook:${get(page, 'id')}`,
        domainTypeId: this.enums.findWhere('DOMAIN_TYPE', { name: 'FacebookPage' }, 'id'),
        name: get(page, 'name'),
        externalLocationId: get(page, 'id'),
        statusTypeId: 1, // TODO: Use Enums.
      });
    // Do this after, because we want to update existing records.
    setProperties(domain, {
      externalApiKeyId: get(page, 'access_token'),
      externalUserId: get(this, 'facebook.userID') || '',
    });
    domains.addObject(domain);

    const orgPromoDomain =
      this.opdForPage(page) ||
      this.store.createRecord('organization-promotion-domain', {
        statusTypeId: 2,
        rootFolder: `fb${Math.floor(Math.random() * 100000 + 1)}`, // Not guaranteed to be unique, but oh well.
        isPrimary: false,
        startDate: get(get(this, 'model.matchups').sortBy('startDate'), 'firstObject.startDate'),
        endDate: get(get(this, 'model.matchups').sortBy('selectionEndDate'), 'lastObject.selectionEndDate'),
        shortcutDomain: get(this, 'model.organizationPromotionDomain.domain'),
        domain,
      });
    get(this, 'model.facebookOrganizationPromotionDomains').addObject(orgPromoDomain);
  },
  removeFromFacebookPage(page) {
    const opds = get(this, 'model.facebookOrganizationPromotionDomains');
    const opd = this.opdForPage(page);
    if (get(opd, 'isNew')) {
      opd.deleteRecord();
      opds.removeObject(opd);
    } else {
      set(opd, 'endDate', new Date());
    }
  },
  removeFromFacebookPageIfNew(page) {
    const opds = get(this, 'model.facebookOrganizationPromotionDomains');
    const opd = this.opdForPage(page);
    if (get(opd, 'isNew')) {
      opd.deleteRecord();
      opds.removeObject(opd);
    }
  },
  //endregion

  deleteDomainRedirection() {
    const { domainRedirection } = this.model;
    if (domainRedirection.isNew) {
      set(this, 'model.domainRedirection', null);
    }
    domainRedirection.deleteRecord();
  },

  //region Observers
  /**
   * The back-end only associates DomainRedirections if the Domain ID and Root Folder (path) match exactly.
   * So we keep them in sync here.
   */
  orgPromoDomainChanged: observer('model.organizationPromotionDomain.{rootFolder,domain.id}', function () {
    const redirect = get(this, 'model.domainRedirection');
    if (redirect) {
      setProperties(redirect, {
        domainId: window.parseInt(get(this, 'model.organizationPromotionDomain.domain.id'), 10),
        path: get(this, 'model.organizationPromotionDomain.rootFolder'),
      });
    }
  }),
  //endregion

  //region Actions
  actions: {
    selectHostingOption(newValue) {
      if (!this.model.domainRedirection) {
        this.createRedirection();
      }
      set(this, 'model.domainRedirection.statusTypeId', newValue);
      if (newValue === 2 && this.model.domainRedirection.isNew) {
        if (this.saveTaskInstance?.isRunning) {
          this.saveTaskInstance.then(() => {
            this.deleteDomainRedirection();
          });
        } else {
          this.deleteDomainRedirection();
        }
      }
    },
    toggleUi(property) {
      this.toggleProperty(property);
    },
    editCustomWrapper() {
      this.createCustomWrappersIfNeeded();
      this.toggleProperty('isEditingCustomWrapper');
    },
    addWrapper() {
      this.createCustomWrappersIfNeeded();
    },
    editDefaultWrapper() {
      this.toggleProperty('isEditingDefaultWrapper');
    },
    editSmallDefaultWrapperType(id) {
      set(this, 'orgDefaultSmallWrapper.wrapperMethodTypeId', id);
    },
    editSmallWrapperType(id) {
      set(this, 'smallDeviceWrapper.wrapperMethodTypeId', id);
    },
    editLargeDefaultWrapperType(id) {
      set(this, 'orgDefaultLargeWrapper.wrapperMethodTypeId', id);
    },
    editLargeWrapperType(id) {
      set(this, 'largeDeviceWrapper.wrapperMethodTypeId', id);
    },
    toggleIsDeletingFacebookPage(page) {
      set(this, 'pageToBeDeleted', page);
      this.toggleProperty('deletingFacebookPage');
    },
    stopDeletingFacebookPage() {
      set(this, 'pageToBeDeleted', null);
      this.toggleProperty('deletingFacebookPage');
    },
    removeFacebookPage() {
      this.pageToBeDeleted.deleteRecord();
      this.toggleProperty('deletingFacebookPage');
    },
    redirect() {
      if (!get(this, 'model.domainRedirection')) {
        this.createRedirection();
      }
      set(this, 'model.domainRedirection.statusType', 'Active');
      document.querySelector('.embed-input').focus();
    },
    addToFacebookPage(page, value) {
      if (value) {
        this.addToFacebookPage(page);
      } else {
        this.removeFromFacebookPageIfNew(page);
      }
    },
    editFacebookPage(organizationPromotionDomain) {
      set(this, 'editingPage', organizationPromotionDomain);
    },
    stopEditingFacebookPage() {
      set(this, 'editingPage', null);
    },
    unpublishFacebookNow(opd) {
      set(opd, 'endDate', new Date());
      set(this, 'editingPage', null);
    },
    publishToFacebookNow(opd) {
      // Set the publish date to one minute from now since the start date must be in the future
      const futureDate = moment().add(1, 'minutes').toDate();
      set(opd, 'startDate', futureDate);
      set(this, 'editingPage', null);
    },
    chooseDomain(domain) {
      if (get(this, 'model.domainRedirection')) {
        set(this, 'model.domainRedirection.statusType', 'InActive');
      }
      set(this, 'model.organizationPromotionDomain.domain', domain);
    },
    changePublishApproval(approved) {
      set(this, 'model.promotionApprovalSetting.value', approved);
    },
    setPublishApprover(approver) {
      set(this, 'publishApprover', approver);
    },
    async sendApprovalRequest() {
      const approver = isPresent(get(this, 'publishApprover.firstName'))
        ? get(this, 'publishApprover.firstName')
        : get(this, 'publishApprover.emailAddress');
      const approvalRequest = this.store.createRecord('promotionApprovalRequest', {
        requestedOrganizationUserId: get(this, 'publishApprover.id'),
      });
      await approvalRequest.save();
      this.snackbar.show(`We have sent ${approver} an email requesting approval.`);
    },
    setEmbedFrameDimensions(dimensions) {
      set(this, 'embedFrameDimensions', dimensions);
    },
    recordAdgagementToFullstory() {
      this.fullStory.logCustomEvent('COPY_ADGAGEMENT', {
        dimensions: this.embedFrameDimensions,
      });
    },
    checkValidUrl({ target: { value } }) {
      set(this, 'isDomainRedirectionValid', isValidUrl(value) || isValidUrl(`https://${value}`));
    },
  },
  //endregion
});
