/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

export default Route.extend({
  //region Dependencies
  enums: service(),
  store: service(),
  current: service(),
  //endregion

  //region Ember Hooks
  async model() {
    const getTemplateTypeIdFor = name => this.enums.findWhere('TEMPLATE_TYPE', { name }, 'id');

    const [privacyPolicyTemplates, rulesTemplates, termsOfServiceTemplates] = await Promise.all([
      this.store.query('design-template', { designTemplateTypeId: getTemplateTypeIdFor('Privacy Policy') }),
      this.store.query('design-template', { designTemplateTypeId: getTemplateTypeIdFor('Rules') }),
      this.store.query('design-template', { designTemplateTypeId: getTemplateTypeIdFor('Terms of Service') }),
    ]);

    return {
      privacyPolicyTemplates: privacyPolicyTemplates.toArray(),
      rulesTemplates: rulesTemplates.toArray(),
      termsOfServiceTemplates: termsOfServiceTemplates.toArray(),
    };
  },
  //endregion

  //region Actions
  actions: {
    removeTemplate(template) {
      this.modelFor(this.routeName)[`${camelize(template.designTemplateType)}Templates`].removeObject(template);
    },

    addTemplate(template) {
      this.modelFor(this.routeName)[`${camelize(template.designTemplateType)}Templates`].addObject(template);
    },
  },
  //endregion
});
