const FIELD_TYPE_NAMES = {
  FacebookLikeApi: 'Facebook Like',
  SingleCheckbox: 'Checkbox',
  Checkboxes: 'Multiple Checkboxes',
  CustomDateInput: 'Date',
  NumberInput: 'Number',
  Textbox: 'Text',
  SelectSingle: 'Drop Down',
  RadioButtons: 'Multiple Choice',
  Textarea: 'Text (Long Response)',
  WatchVideo: 'Embedded Video',
  DisplayText: 'Description Text',
  TwitterFollowApi: 'X Follow',
  TwitterTweetApi: 'Post on X',
  Optin: 'Opt-in',
  WebLink: 'Web Link',
  MobileApp: 'Mobile App',
  SmartSpeakerSkill: 'Alexa Skill',
  Instagram: 'Instagram',
  FacebookLink: 'Facebook',
  CodewordForExtraChances: 'Codeword',
  XLink: 'X',
};

const FIELD_TYPE_DEFAULT_VALUES = {
  SingleCheckbox: true,
  Optin: false,
};

export function getFieldTypeName(fieldType) {
  return FIELD_TYPE_NAMES[fieldType];
}

export function getFieldTypeDefaultValue(fieldType) {
  return FIELD_TYPE_DEFAULT_VALUES[fieldType];
}
