/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, get } from '@ember/object';
import { alias, and, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import moment from 'moment';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo, hasMany } = DS;

function getOrgLocalTime(dateAttribute, orgUTCOffset) {
  return computed(dateAttribute, orgUTCOffset, function () {
    const momentDate = moment(get(this, dateAttribute));
    const orgUTCOffsetAdjusted = momentDate.isDST() ? get(this, orgUTCOffset) + 1 : get(this, orgUTCOffset);
    const offsetDate = momentDate.utcOffset(orgUTCOffsetAdjusted * 60);
    return `${offsetDate.format('dddd, MMMM Do YYYY')} at ${offsetDate.format('h:mma')}`;
  });
}

const NON_COPYABLE_PROMOTION_TYPES = [
  'BallotVotersChoice',
  'PickemBracket',
  'BallotBracket',
  'BallotBracketUpFront',
  'PickemBracketUpFront',
];

/**
 * @typedef {DS.Model} OrganizationPromotion
 */
export default BaseModel.extend({
  //region Ember Hooks
  enums: service(),
  //endregion

  features: service(),

  //region Properties
  name: attr('string'),
  statusTypeId: attr('number'),
  uniqueId: attr('string'),
  organizationId: attr('number'),
  promotionId: attr('number'),
  contestId: attr('number'),
  hasPendingEntries: attr('boolean'),
  defaultLanguageId: attr('number'),
  //Summarized Properties:
  isCurrent: attr('boolean'),
  isOldPlatform: attr('boolean'),
  kpi: attr('number'),
  numEligibleUsers: attr('number'),
  numVotes: attr('number'),
  numEligibleWriteins: attr('number'),
  numEligibleEntries: attr('number'),
  numEligibleVotes: attr('number'),
  numEligibleFormsubmissions: attr('number'),
  numUniquePeopleWithEnteries: attr('number'),
  numUniquePeopleWithVotes: attr('number'),
  numOrders: attr('number'),
  ordersGrossRevenue: attr('number'),
  organizationName: attr('string'),
  startDate: attr('date'),
  endDate: attr('date'),
  consumerUrl: attr('string'),
  numIncompleteCategories: attr('number'),
  submissionStartDate: attr('date'),
  submissionEndDate: attr('date'),
  selectionStartDate: attr('date'),
  selectionEndDate: attr('date'),
  numPendingEntries: attr('number'),
  dateCreated: attr('date'),
  enableFacebookLikeUrl: attr('boolean'),
  enableInstagramHandle: attr('boolean'),
  enableLinkedinProfileUrl: attr('boolean'),
  enableSnapchatHandle: attr('boolean'),
  enableTiktokHandle: attr('boolean'),
  enableTwitterHandle: attr('boolean'),
  enableVimeoChannelUrl: attr('boolean'),
  enableYoutubeChannelUrl: attr('boolean'),
  numActiveVotingEntries: attr('number'),
  sourceOrganizationName: attr('string'),
  destinationCopiedCount: attr('number'),
  sourceOrganizationId: attr('number'),
  organizationRegistrationFormSetting: attr('number'),
  //endregion

  //region Properties
  isJustCreated: false, // a transient property that is only true after a promo is created and before the promotion-basics modal is closed
  isJustCopied: false, // a transient property that is only true after a copy
  //endregion

  //region Computed Properties
  intId: computed('id', function () {
    return window.parseInt(this.id, 10);
  }),
  isSubmissionEndDateDirty: dirtyProperty('submissionEndDate'),
  isSubmissionStartDateDirty: dirtyProperty('submissionStartDate'),
  isNameDirty: dirtyProperty('name'),
  isDefaultLanguageDirty: dirtyProperty('defaultLanguageId'),
  isTest: computed('statusTypeId', function () {
    return this.statusTypeId === this.enums.findWhere('STATUS_TYPE', { name: 'Hidden' });
  }),
  isActive: computed('statusTypeId', function () {
    return this.statusTypeId === this.enums.findWhere('STATUS_TYPE', { name: 'Active' });
  }),
  isInActive: computed('statusTypeId', function () {
    return this.statusTypeId === this.enums.findWhere('STATUS_TYPE', { name: 'InActive' });
  }),
  matchupsAsCategories: or(
    'promotion.isPhotoGallery',
    'promotion.isPhotoVotingStandard',
    'promotion.isVideoVotingStandard',
    'promotion.isBallot'
  ),
  get submissionHasEnded() {
    const now = new Date();
    return now > this.submissionEndDate;
  },
  get promotionHasEnded() {
    const now = new Date();
    return now > this.endDate;
  },
  promotionCreatorHasFullName: and('createdByOrganizationUser.{firstName,lastName}'),
  promotionCreatorFullName: computed(
    'promotionCreatorHasFullName',
    'createdByOrganizationUser.{firstName,lastName}',
    function () {
      if (!this.promotionCreatorHasFullName) {
        return '';
      }
      return `${get(this, 'createdByOrganizationUser.firstName')} ${get(this, 'createdByOrganizationUser.lastName')}`;
    }
  ),
  isSocialSettingDirty: computed(
    'enableFacebookLikeUrl',
    'enableInstagramHandle',
    'enableLinkedinProfileUrl',
    'enableSnapchatHandle',
    'enableTiktokHandle',
    'enableTwitterHandle',
    'enableVimeoChannelUrl',
    'enableYoutubeChannelUrl',
    'hasDirtyAttributes',
    function () {
      if (!this.hasDirtyAttributes) {
        return false;
      }
      const socialSettings = [
        'enableFacebookLikeUrl',
        'enableInstagramHandle',
        'enableLinkedinProfileUrl',
        'enableSnapchatHandle',
        'enableTiktokHandle',
        'enableTwitterHandle',
        'enableVimeoChannelUrl',
        'enableYoutubeChannelUrl',
      ];
      return socialSettings.some(setting => !!this.changedAttributes()[setting]);
    }
  ),
  /**
   * These are used in the preview of the promo email designer to show dates as they will be seen by recipients
   */
  orgTimeZoneSubmissionStartDate: getOrgLocalTime('submissionStartDate', 'organization.timeZone.offset'),
  orgTimeZoneSubmissionEndDate: getOrgLocalTime('submissionEndDate', 'organization.timeZone.offset'),
  orgTimeZoneSelectionStartDate: getOrgLocalTime('selectionStartDate', 'organization.timeZone.offset'),
  orgTimeZoneSelectionEndDate: getOrgLocalTime('selectionEndDate', 'organization.timeZone.offset'),
  //endregion

  //region Relationships
  createdByOrganizationUser: belongsTo('partner-organization-user'),
  organization: belongsTo('organization', { async: true }),
  settings: hasMany('setting', {
    async: false,
  }),

  organizationPromotionLinks: hasMany('organization-promotion-link'),

  // When api returns embedded records it uses plural
  // To get around that we use an alias because this relationship is to only one promotion
  promotions: belongsTo('promotion', {
    async: false,
  }),
  promotion: alias('promotions'),
  isSyndicatedFrom: computed('promotion', 'organization', function () {
    return Number(this.organization.get('id')) !== this.promotion.organizationId;
  }),
  hasSyndicates: computed('promotion.{isSyndicated,isInherited}', function () {
    return this.promotion.isSyndicated && !this.promotion.isInherited;
  }),
  isCopyable: computed('promotion.promotionSubType', 'isSyndicatedFrom', function () {
    return !NON_COPYABLE_PROMOTION_TYPES.includes(this.promotion.promotionSubType) && !this.isSyndicatedFrom;
  }),

  ad() {
    return this.store.peekAll('sponsored-post').findBy('ownerEntityId', +this.id);
  },

  //endregion
});
