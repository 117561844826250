/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { alias, not, notEmpty, or, readOnly } from '@ember/object/computed';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import displayableFields from 'partner/utils/displayable-fields';
import { getFieldTypeName } from 'partner/utils/field-type-metadata';
import isAnyPath from 'partner/utils/is-any-path';
import { unloadEmptyRecords } from 'partner/utils/store-unload';
import { AbortedByUser } from 'secondstreet-common/utils/errors';

// the limit is set by the API
const FIELD_OPTION_NAME_LIMIT = 500;

const rootOrganizationId = 1;
const YOUTUBE_HOST_TYPE_ID = 2;
const VIDEO_MEDIA_TYPE_ID = 3;

const FIELD_TYPES_WITH_OPTIONS = [
  'RadioButtons',
  'SelectMultiple',
  'SelectSingle',
  'Checkboxes',
  'WebLink',
  'MobileApp',
  'SmartSpeakerSkill',
  'Instagram',
  'FacebookLink',
  'XLink',
];

const FIELD_NAMES_IN_REPORT = {
  13: 'Media URL', // PhotoEntryUpload
  14: 'Media URL', // VideoEntryUpload
  3: 'Name', // PhotoTitle,
  4: 'Description', // PhotoCaption
  21: 'Media Release Consent', // MediaReleaseConsent
};

const CONFIRM_MSG = 'Are you sure you want to rename this opt-in and share its pre-existing data with the following?';

export default Component.extend({
  //region Ember Hooks
  permissions: service(),
  store: service(),
  features: service(),
  router: service(),
  current: service(),

  didInsertElement() {
    this._super(...arguments);
    if (this.hideLabelText) {
      const input = this.element.querySelector('.label-input');
      // Sets the focus to the end of the input
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    }
  },

  init() {
    this._super(...arguments);
    set(this, 'confirmedModels', []);
    set(this, 'existingFields', []);

    if (!this['save-form']) set(this, 'save-form', () => {});
  },

  didReceiveAttrs() {
    this._super();
    // it is possible that the field name changes when the form field text is edited
    // we still need to display the original field name, so we should capture it here
    set(this, 'originalFieldName', this['form-field'].field.name);
  },
  //endregion

  //region Attributes
  /**
   * Entry forms, as opposed to registration forms and default forms, have slightly different behavior and UI
   * @property {Boolean}
   */
  'entry-form': false,
  /**
   * The function for saving the form/fields
   * @property {Function}
   */
  'save-form'() {},
  fetchForm() {},
  /**
   * The action to call when changes are complete (saved)
   * @property {Function}
   */
  'something-finished-changing'() {},
  'hide-starred': false,
  'disable-help-text': false,
  'dips-url': null,
  /** @property {OptinNotificationContext} */
  optinNotificationContext: null,
  //endregion

  //region Properties
  newFieldOption: '',
  newCodewordOption: '',
  confirmedModels: null,
  existingFields: null,
  originalFieldName: '',
  videoToRemove: null,
  //endregion

  //region Computed Properties
  formField: alias('form-field'),

  fieldTypeName: computed('form-field.fieldType', function () {
    return getFieldTypeName(this['form-field'].fieldType);
  }),
  fieldInheritedFromRoot: computed('form-field.field.{isEditable,organizationId}', function () {
    const { field } = this['form-field'];
    if (field) {
      return !field.isEditable && field.organizationId === rootOrganizationId;
    }
    return;
  }),

  iosUrl: computed('form-field.field.fieldOptions.[]', {
    get() {
      return get(this, 'form-field.field.fieldOptions').find(option => option.text === 'ios');
    },
    set(key, value) {
      if (get(this, 'form-field.field.fieldOptions').find(option => option.text === 'ios')) {
        set(
          get(this, 'form-field.field.fieldOptions').find(option => option.text === 'ios'),
          'name',
          value
        );
      } else {
        this.store.createRecord('fieldOption', {
          name: value,
          text: 'ios',
          field: this['form-field'].field,
          displayOrder: 1,
          isJustCreated: true,
          fieldOptionTypeId: 1,
        });
      }
      return value;
    },
  }),

  androidUrl: computed('form-field.field.fieldOptions.[]', {
    get() {
      return get(this, 'form-field.field.fieldOptions').find(option => option.text === 'android');
    },
    set(key, value) {
      if (get(this, 'form-field.field.fieldOptions').find(option => option.text === 'android')) {
        set(
          get(this, 'form-field.field.fieldOptions').find(option => option.text === 'android'),
          'name',
          value
        );
      } else {
        this.store.createRecord('fieldOption', {
          name: value,
          text: 'android',
          field: this['form-field'].field,
          displayOrder: 2,
          isJustCreated: true,
          fieldOptionTypeId: 1,
        });
      }
      return value;
    },
  }),

  /**
   * We want to know if the label text has been changed for both persisted and isNew form fields.
   * If autosave is implemented (and editing isNew form fields becomes impossible) this can be refactored to check for dirty properties directly.
   * @returns {Boolean}
   */
  isLabelTextDirty: computed('form-field.{labelText,field.labelText}', function () {
    const formFieldLabel = this['form-field'].labelText;
    const fieldLabel = this['form-field'].field.labelText;
    return isPresent(fieldLabel) && formFieldLabel !== fieldLabel;
  }),
  showConfirmationMessage: computed('isLabelTextDirty', 'entry-form', function () {
    return this.isLabelTextDirty && !this['entry-form'];
  }),
  isAnythingDirty: isAnyPath('isDirty', [
    'form-field',
    'form-field.field',
    'form-field.field.fieldOptions.[]',
    'optinNotificationContext',
  ]),
  isAnythingSaving: or('is-anything-saving', 'closeModalTask.isRunning', 'optinNotificationContext.isSaving'),
  form: readOnly('form-field.formPage.form'),
  notEditable: computed('form-field.field.{isEditable,organizationId}', 'form-field.fieldType', function () {
    const { fieldType } = this['form-field'];
    return (
      !this['form-field'].field.isEditable &&
      !this['form-field'].field.organizationId === rootOrganizationId &&
      fieldType !== 'DisplayText' &&
      fieldType !== 'Codeword'
    );
  }),

  sortedFieldOptions: computed('form-field.field.fieldOptions.@each.{displayOrder,isDeleted,hasErrors}', function () {
    const fieldOptions = this['form-field'].field.fieldOptions.filterBy('isDeleted', false);

    if (!isEmpty(fieldOptions)) {
      return fieldOptions.sortBy('displayOrder');
    }

    return [];
  }),

  showAllowVideoUpload: computed('form-field.fieldType', function () {
    return this.current.promotion?.isUgc && this['form-field'].fieldType === 'ImageSelect';
  }),
  showAllowPhotoUpload: computed('form-field.fieldType', function () {
    return this.current.promotion?.isUgc && this['form-field'].fieldType === 'VideoSelect';
  }),
  isNotRemovable: not('form-field.isRemovable'),
  isCheckboxesOrRadio: or('form-field.isRadioButtons', 'form-field.isCheckboxes'),
  disableParticipationEnabledFields: computed('formField', 'participationEnabled', function () {
    if (
      this.router.currentRouteName !==
        'organizations.organization.organization-promotions.organization-promotion.setup.forms.form' ||
      this.participationEnabled == undefined
    )
      return false;
    return this.participationEnabled.toArray()?.any(country => country.postalCodes?.length)
      ? this.formField.participationNonRemovableFieldsWithZipcode
      : this.participationEnabled.length && this.formField.participationNonRemovableFieldsWithoutZipcode;
  }),
  disableRequire: or('isNotRemovable', 'disableFirstPageRequire', 'disableParticipationEnabledFields'),
  disableInput: computed('entry-form', 'notEditable', 'disableDetails', function () {
    return this['entry-form'] ? false : this.notEditable || this.disableDetails;
  }),

  resolvedTitle: computed('title', 'form-field.fieldType', function () {
    if (this.title) return this.title;

    switch (this['form-field'].fieldType) {
      case 'DisplayText':
        return 'Description Text';
      case 'CustomDateInput':
        return 'Date Selection Question Text';
      case 'NumberInput':
        return 'Number Selection Question Text';
      case 'RadioButtons':
        return 'Multiple Choice Question Text';
      case 'Checkboxes':
        return 'Multiple Checkboxes Question Text';
      case 'SingleCheckbox':
        return 'Checkbox Question Text';
      case 'Optin':
        return 'Opt-in Question Text';
      case 'SelectSingle':
        return 'Drop Down Selection Question Text';
      case 'ExtraChances':
        return 'Referral Text';
      case 'WatchVideo':
        return 'Watch a Video Text';
      case 'WebLink':
      case 'Instagram':
      case 'FacebookLink':
      case 'XLink':
        return 'Link Text';
      case 'MobileApp':
      case 'SmartSpeakerSkill':
      case 'CodewordForExtraChances':
        return 'Call To Action Text';
      default:
        return 'Question Title';
    }
  }),

  isCopyEnabled: computed('form-field.{fieldType,field.isEditable}', function () {
    return (
      this['form-field']?.field?.isEditable &&
      ['SelectSingle', 'RadioButtons', 'Checkboxes'].includes(this['form-field']?.fieldType)
    );
  }),

  isLimitSelectionEnabled: computed('form-field.{fieldType,field.isEditable}', function () {
    return this.formField?.field?.isEditable && ['Checkboxes'].includes(this.formField?.fieldType);
  }),

  isAnswerLimitValid: computed(
    'isLimitSelectionEnabled',
    'sortedFieldOptions',
    'form-field.{isLimitSelection,answerLimit}',
    function () {
      return this.isLimitSelectionEnabled && this.formField?.isLimitSelection
        ? this.formField?.answerLimit > 0 && this.formField?.answerLimit <= this.sortedFieldOptions.length
        : true;
    }
  ),

  extraChancesOptions: computed('form-field', function () {
    const formField = this['form-field'];
    const extraChances = Number.parseInt(formField.extraChances, 10);
    const { extraChancesMaximum } = formField;
    const maximumChances = Number.parseInt(extraChancesMaximum, 10);
    const once = { value: extraChances, label: 'Only Once' };
    const perReferral = { value: -1, label: 'Per Referral' };
    if (extraChancesMaximum && maximumChances !== -1 && maximumChances !== extraChances) {
      return [once, perReferral, { value: maximumChances, label: `Max of ${maximumChances} Extra Chances` }];
    }
    return [once, perReferral];
  }),
  /**
   * Whether we should lock down most of the properties for editing, except for "required."
   * @property {Boolean}
   */
  disableDetails: computed('form-field.fieldType', 'permissions.permissions.@each.permissionTypeId', function () {
    if (this['form-field'].fieldType === 'Optin') {
      return !this.permissions.getAccessLevel('GlobalOptin').administer;
    }
    return false;
  }),
  /**
   * Disables the done button if you have no label text or if one of your field options is empty
   * @property {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  isSaveDisabled: computed(
    'entry-form',
    'form-field.{labelText,isEditable}',
    'showOptions',
    'emptyFieldOptions',
    'form-field.fields.{fieldOptions.length,relatedUrl,requiredText,mediaItem,isWatchVideo}',
    'isAnythingSaving',
    'isAnythingDirty',
    'optinNotificationContext.isSaveDisabled',
    'isAnswerLimitValid',
    function () {
      if (
        !this.isAnythingDirty ||
        this.isAnythingSaving ||
        this.optinNotificationContext.isSaveDisabled ||
        !this.isAnswerLimitValid
      ) {
        return true;
      }

      if (this['form-field'].field.isWatchVideo && !this['form-field'].field.mediaItem) {
        return true;
      }

      if (this['entry-form'] || this['form-field'].isEditable) {
        if (!this['form-field'].labelText) {
          if (!this['form-field'].fields.relatedUrl && !this['form-field'].fields.requiredText) {
            return true;
          }
        } else if (this.showOptions) {
          if (
            this['form-field'].fields.fieldOptions.filterBy('isDeleted', false).length < 2 &&
            !this.isExtraChanceURL
          ) {
            return true;
          } else if (this.emptyFieldOptions) {
            return true;
          }
        } else if (this['form-field'].fieldType === 'CodewordForExtraChances') {
          if (this['form-field'].fields.fieldOptions.filterBy('isDeleted', false).length < 1) {
            return true;
          } else if (this.emptyFieldOptions) {
            return true;
          }
        }
      }
      return;
    }
  ),
  /**
   * Whether or not this is an extra chance form field that has a URL as an input. These inputs will
   * typically have the URL saved in the name field for fieldOptions which is not default behavior.
   * @property {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  isExtraChanceURL: computed('form-field.fieldType', function () {
    if (
      ['WebLink', 'MobileApp', 'SmartSpeakerSkill', 'Instagram', 'FacebookLink', 'XLink'].includes(
        this['form-field'].fieldType
      )
    ) {
      return true;
    }
    return false;
  }),
  showPrechecked: computed(
    'form-field.{isOptin,isCheckbox}',
    'extra-chances-enabled',
    'features.hideOptinPrecheck',
    function () {
      return (
        ((this['form-field'].isOptin && !this.get('features.hideOptinPrecheck')) || this['form-field'].isCheckbox) &&
        !this['extra-chances-enabled']
      );
    }
  ),
  /**
   * Whether or not we show the field options
   * @property {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  showOptions: computed('form-field.fieldType', function () {
    return FIELD_TYPES_WITH_OPTIONS.includes(this['form-field'].fieldType);
  }),
  emptyFieldOptions: computed('form-field.fields.fieldOptions.@each.{text,isDeleted,name}', function () {
    if (this.isExtraChanceURL) {
      const namePresent = !this['form-field'].fields.fieldOptions.any(fieldOption => isPresent(fieldOption.name));
      return namePresent;
    }
    return !isEmpty(this['form-field'].fields.fieldOptions.filter(option => option.text === '' && !option.isDeleted));
  }),
  hideLabelText: or(
    'form-field.field.isFacebookLikeApi',
    'form-field.field.isTwitterFollowApi',
    'form-field.field.isTwitterTweetApi'
  ),
  // Twitter Follow fields can not be required on the first page
  disableFirstPageRequire: computed('form-field.{formPage.[],field.isTwitterFollowApi}', function () {
    return this['form-field'].field.isTwitterFollowApi && this['form-field'].formPage.pageNumber === 1;
  }),
  resolvedHideRequire: or(
    'hideRequire',
    'form-field.field.{isFacebookLikeApi,isFacebookLink,isInstagram,isTwitterFollowApi,isTwitterTweetApi,isDisplayText,isXLink}',
    'extra-chances-enabled',
    'form-field.isYesnoFormat'
  ),
  hideKeepResponsePrivate: computed('showKeepResponsePrivate', 'form-field.field.isDisplayText', function () {
    return this['form-field'].field.isDisplayText || !this.showKeepResponsePrivate;
  }),
  showExistingFields: notEmpty('existingFields'),
  /**
   * The existing fields that aren't already on the form
   * @return {Field[]} array of existing fields not on a formPage
   */
  displayedExistingFields: computed('existingFields', function () {
    return displayableFields(this.existingFields, this['form-field'].formPage);
  }),
  noExistingFieldsAvailable: computed(
    'displayedExistingFields',
    'existingFields',
    'form-field.field.isTwitterTweetApi',
    function () {
      if (this['form-field'].field.isTwitterTweetApi) {
        return this.displayedExistingFields.length === 0;
      }
      return this.existingFields.length > 0 && this.displayedExistingFields.length === 0;
    }
  ),

  fieldNameInReport: computed('form-field.field.fieldTypeId', 'originalFieldName', function () {
    const key = get(this, 'form-field.field.fieldTypeId');
    return FIELD_NAMES_IN_REPORT[key] || this.originalFieldName;
  }),

  isAnyFieldOptionDirty: isAnyPath('hasDirtyAttributes', ['form-field.field.fieldOptions.[]']),

  showCodewordUpdateWarning: computed('form-field', 'isAnyFieldOptionDirty', function () {
    return !this['form-field'].isNew && this.isAnyFieldOptionDirty;
  }),
  //endregion

  //region Observers
  createNewFieldOption: observer('newFieldOption', function () {
    this._addNewFieldOption(this.newFieldOption);
  }),
  optinNameChanged: observer('form-field.{labelText,starredNickname,isStarred}', function () {
    if (this['form-field'].field && this['form-field'].field.hasGlobalOptin) {
      this['form-field'].field.globalOptin.errors.clear();
    }
  }),
  //endregion

  //region Methods
  _addNewFieldOption(name) {
    this.store.createRecord('fieldOption', {
      name,
      text: name,
      field: this['form-field'].field,
      displayOrder: this['form-field'].field.fieldOptions.filterBy('isDeleted', false).length + 1 || 1,
      isJustCreated: true,
    });
    set(this, 'newFieldOption', '');
    set(this, 'newCodewordOption', '');
  },
  /**
   * In all cases, this at least sets the given property to a value on the model.
   * It also handles edge cases where that value needs to be synced to another property or model.
   * @param {DS.Model} model
   * @param {String} propertyName
   * @param value
   */
  syncProperties(model, propertyName, value) {
    set(model, propertyName, value);

    //region Edge Cases
    // We want to sync the field name with the form field label text on input in case the user edits the label text and then
    // reveals the field name input by starring the field - we want the input to have the label text as it currently is
    // with any subsequent changes to the label text not affecting the field name
    if (
      model.constructor.modelName === 'form-field' &&
      propertyName === 'labelText' &&
      !model.isStarred &&
      !this.fieldInheritedFromRoot
    ) {
      set(model, 'field.name', value);
    }
    //endregion
  },

  /**
   * Input value was changed, update related model accordingly.
   * @param {DS.Model} model - model that will be updated
   * @param {String} propertyName - property to update on the model
   * @param {String} value -  value user has typed into the input
   * @param {Event} event -  event from the oninput event triggered on the input
   * */
  handleInputChange(model, propertyName, value, event) {
    const inputStart = event.target.selectionStart;
    const inputEnd = event.target.selectionEnd;
    this.syncProperties(model, propertyName, value);
    scheduleOnce('afterRender', this, () => {
      event.target.selectionStart = inputStart;
      event.target.selectionEnd = inputEnd;
    });
  },

  checkForExistingFieldError() {
    const formField = this['form-field'];
    const errors = (
      formField.isStarred && formField.field.hasGlobalOptin
        ? formField.field.globalOptin.errors
        : formField.field.errors
    ).toArray();
    if (errors.length && errors.firstObject.message.includes('with this name already exists')) {
      this.getAndSetExistingFieldsTask.perform();
    }
  },

  confirmSave() {
    const { isSharing, optinNotifications } = this.optinNotificationContext;

    if (isSharing && this.showConfirmationMessage) {
      const emails = optinNotifications.rejectBy('isDeleted').mapBy('emailAddress');

      if (!isEmpty(emails)) {
        const PREFIX = '\n    - ';

        if (!window.confirm(`${CONFIRM_MSG}\n${PREFIX}${emails.join(PREFIX)}`)) {
          throw new AbortedByUser('During field rename + sharing check.');
        }
      }
    }

    return true;
  },
  //endregion

  //region Actions
  actions: {
    addNewFieldOption(name) {
      this._addNewFieldOption(name);
    },

    toggleRequire() {
      if (!this.disableRequire) {
        this.toggleProperty('form-field.isRequired');
      }
    },

    toggleStar() {
      if (!this.disableDetails) {
        this.toggleProperty('form-field.field.isStarred');
      }
    },

    setLabel(value) {
      this.syncProperties(this['form-field'], 'labelText', value);
    },

    toggleIsLimitSelection() {
      this.toggleProperty('formField.isLimitSelection');
      this.formField.answerLimit = this.formField.isLimitSelection ? this.sortedFieldOptions?.length : null;
    },

    toggleFormFieldProperty(key) {
      this.toggleProperty(`form-field.${key}`);
    },

    inputChanged(model, attr, value, event) {
      this.handleInputChange(model, attr, value, event);
    },

    updateExtraChancesMaximum({ value }) {
      set(this, 'form-field.extraChancesMaximum', value);
    },

    closeModal() {
      return this.closeModalTask.perform();
    },

    /**
     * This is copied from the form-field route, we need to fix this to avoid duplicate code
     * @param formField
     */
    cancelAndRollBackChanges() {
      this.optinNotificationContext.cancel();
      const formField = this['form-field'];
      const { field } = formField;

      this.stopEditingFormField(false);

      // calling slice creates a copy of the array so that removing the field-options doesn't alter the forEach counter
      (field.fieldOptions || []).slice().map(option => option.rollbackAttributes());

      if (formField.isJustCreated) {
        this.form.deletedRecords.addObject(formField);
        formField.deleteRecord();

        // The field may have references elsewhere if it was starred, so we should clean those up.
        set(field, 'isStarred', false);
      } else {
        formField.rollbackAttributes();
      }

      const removedVideo = this.videoToRemove;
      if (removedVideo) {
        // manually rollback the field's removed video because Ember doesn't know how to rollback relationships
        set(this, 'form-field.field.mediaItem', removedVideo);
        set(this, 'videoToRemove', null);
      }

      this['something-finished-changing']();

      field.rollbackAttributes();
      /* 
        we unload empty records as the embedded errors without any ids gets attached to wrong records
      */
      unloadEmptyRecords(this.store, 'field');
      unloadEmptyRecords(this.store, 'form-field');
      unloadEmptyRecords(this.store, 'global-optin');
    },

    removeFieldOption(fieldOption) {
      this.form.deletedRecords.addObject(fieldOption);
      fieldOption.deleteRecord();
      this.send('reorderFieldOptions', this.sortedFieldOptions);
    },

    reorderFieldOptions(fieldOptions) {
      fieldOptions.forEach((fieldOption, index) => {
        set(fieldOption, 'displayOrder', index + 1);
      });
    },

    resetAnswerLimit() {
      if (this.formField.isLimitSelection && this.formField.answerLimit > this.sortedFieldOptions.length) {
        this.formField.answerLimit = this.sortedFieldOptions.length;
      }
    },
    addExistingFieldToForm(field) {
      this.createFormField(this['form-field'].formPage, field);
      this.send('removeFormField', this['form-field']);
    },

    async addVideo({ type, value, mediaItem, meta }) {
      if (type == 'youtube') {
        const newMediaItem = this.store.createRecord('media-item', {
          contentType: 'video',
          mediaTypeId: VIDEO_MEDIA_TYPE_ID,
          mediaHostTypeId: YOUTUBE_HOST_TYPE_ID,
          fileName: value,
          externalSourceUrl: meta,
        });

        set(this, 'form-field.field.mediaItem', await newMediaItem.save());
      } else {
        set(this, 'form-field.field.mediaItem', mediaItem);
      }
    },

    removeVideo() {
      set(this, 'videoToRemove', this['form-field'].field.mediaItem);
      set(this, 'form-field.field.mediaItem', null);
      this['form-field'].field.forceDirty();
    },

    /**
     * This is copied from the form-field route, we need to fix this to avoid duplicate code
     * @param formField
     */
    removeFormField(formField) {
      this.send('closeModal');
      if (isPresent(formField.field.fieldOptions)) {
        // creates a copy of the array so that removing the field-options doesn't alter the forEach counter
        const fieldOptions = formField.field.fieldOptions.slice();
        fieldOptions.forEach(x => x.rollbackAttributes());
      }

      this.form.deletedRecords.addObject(formField);

      // The field may have references elsewhere if it was starred, so we should let those clean up.
      if (formField.field.isNew) {
        set(formField, 'field.isStarred', false);
      }
      formField.field.rollbackAttributes();
      formField.deleteRecord();
    },

    async createAndEditField(selectedFieldType) {
      const fieldType = selectedFieldType || this['form-field'].fieldType;
      const { formPage } = this['form-field'];

      this.send('cancelAndRollBackChanges');

      const field = await this.createField(fieldType);
      const formField = await this.createFormField(formPage, field);

      this.editFormField(formField);
    },

    copyFormField() {
      this.copyFormField(this['form-field']);
    },
  },
  //endregion

  //region Tasks
  closeModalTask: task(function* () {
    try {
      this.confirmSave();
      this.optinNotificationContext.confirmSave();
    } catch (e) {
      if (e instanceof AbortedByUser) {
        return;
      }
      throw e;
    }

    const formField = this['form-field'];
    if (formField.field.isEditable) {
      // When the field is starred
      if (formField.field.isStarred) {
        // If the field is starred and it has a global optin, set the name of the global optin name to the form field name
        if (isPresent(formField.field.globalOptins) && isPresent(formField.field.name)) {
          set(formField, 'field.globalOptin.name', formField.field.name);
        }
      } else {
        // When the field isn't starred
        // Set the field name to a truncated label or related URL if Facebook
        if (formField.field.isFacebookLikeApi && isEmpty(formField.field.name)) {
          set(formField, 'field.name', formField.field.relatedUrl.substring(0, FIELD_OPTION_NAME_LIMIT));
        } else if (!formField.field.isTwitterTweetApi && !formField.field.isTwitterFollowApi) {
          if (isPresent(formField.labelText) && isEmpty(formField.field.name)) {
            // do NOT truncate the label text here
            set(formField, 'field.name', formField.labelText);
          }
        }

        // Also set the global opt-in name to the form-field.field.name, or fall back to the form-field.labelText
        if (isPresent(formField.field.globalOptins)) {
          if (isPresent(formField.field.name)) {
            set(formField, 'field.globalOptin.name', formField.field.name);
          } else if (isPresent(formField.labelText)) {
            set(formField, 'field.globalOptin.name', formField.labelText);
          }
        }
      }

      // If the field has field options, set their names to be truncated versions of their text
      const { fieldOptions } = formField.field;
      if (isPresent(fieldOptions) && !this.isExtraChanceURL) {
        fieldOptions.rejectBy('isDeleted').forEach(fieldOption => {
          if (isPresent(fieldOption.text)) {
            set(fieldOption, 'name', fieldOption.text.substring(0, FIELD_OPTION_NAME_LIMIT));
          }
        });
      }

      // Set the field's label text to be the form field's label text. This becomes the new "default" label text
      // Do the same for the field's help text
      set(formField, 'field.labelText', formField.labelText);
      set(formField, 'field.helpText', formField.helpText);

      // If this is an opt-in, we should also update the opt-in default label
      if (isPresent(formField.field.globalOptins) && isPresent(formField.labelText)) {
        set(formField, 'field.globalOptin.defaultLabel', formField.labelText);
      }

      // Set the optin field's default yes-no format to be the form field's yes-no format.
      if (formField.fieldType === 'Optin') {
        set(formField, 'field.isYesnoFormat', formField.isYesnoFormat);
      }

      if (formField.fieldType === 'Checkboxes') {
        set(formField, 'field.isLimitSelection', formField.isLimitSelection);
        set(formField, 'field.answerLimit', formField.answerLimit);
      }
    }

    // Remove any empty mobile app records
    if (formField.fieldType === 'MobileApp') {
      const deletedField = formField.field.fieldOptions.find(option => isBlank(option.name));
      if (deletedField) {
        deletedField.deleteRecord();
      }
    }

    // The form field was no longer just created so from now on we can use the "edit" state
    set(formField, 'isJustCreated', false);

    try {
      yield this['save-form']();
      // Capture the dirty state before saving the notification context
      const wereOptinSharedDataFieldsDirty = this.optinNotificationContext.hasDirtyAttributes;
      yield this.optinNotificationContext.save();
      this.stopEditingFormField(false);
      if (this['form-field'].isOptin && wereOptinSharedDataFieldsDirty) {
        this.fetchForm(this.optinNotificationContext);
      }
    } catch (e) {
      console.error(e);
      this.checkForExistingFieldError();

      // Restore missing fieldOption on error
      if (formField.fieldType === 'MobileApp' && formField.field.fieldOptions.length === 1) {
        this.restoreFieldOptions(formField);
      }
    } finally {
      this['something-finished-changing']();
    }
  }),

  getAndSetExistingFieldsTask: task(function* () {
    // We want to include the entry form fields, if the passed field belongs to one
    const { isEntrySubmission } = this['form-field'].formPage.form;

    const query = {
      searchText: this['form-field'].field.name,
      includeEntryFields: isEntrySubmission,
    };

    const existingFields = yield this.store.query('field', query);

    set(this, 'existingFields', existingFields.toArray());
  }),
  //endregion
});
