/* eslint-disable ember/no-computed-properties-in-native-classes */
import { set } from '@ember/object';
import PerformanceReportFixedHeaderController from './performance-report-fixed-header-controller';
import { inject as service } from '@ember/service';
import { action, computed, setProperties } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type CurrentService from 'partner/services/current';
import type ReportsService from 'partner/services/reports';
import type SnackbarService from 'secondstreet-common/services/snackbar';
import {
  DATE_RANGES,
  DATE_RANGE_30_DAYS,
  DATE_RANGE_CUSTOM,
  determineDateRange,
} from 'partner/utils/performance-report';
import moment from 'moment';
import { task, timeout } from 'ember-concurrency';
import { ReportType } from 'partner/services/reports';
import { isEmpty } from '@ember/utils';
import duration from 'secondstreet-common/utils/duration';
import type { DateRangeType, SortDirectionType } from 'partner/utils/performance-report';
import { Model } from './performance-report-route';
import { debounce } from '@ember/runloop';

type FilterType = {
  name: string;
  id: number | string;
};

export default class PerformanceReportCommonController extends PerformanceReportFixedHeaderController {
  @service declare reports: ReportsService;
  @service declare current: CurrentService;
  @service declare features: any;
  @service declare snackbar: SnackbarService;

  declare model: Model;

  queryParams = [
    'view',
    'page',
    'sortColumn',
    'organizationIds',
    'promotionSubTypeIds',
    'type',
    'category',
    'name',
    'dateRange',
    'start',
    'end',
    'sortDirection',
    'status',
  ];

  @tracked tempSelectedOrgs = '';
  @tracked tempSelectedTypes = '';
  @tracked view = 'summary';
  @tracked page = 1;
  @tracked sortColumn?: string;
  @tracked sortDirection: SortDirectionType = null;
  @tracked organizationIds = '';
  @tracked type: string | null = null;
  @tracked category?: string;
  @tracked name = '';
  @tracked dateRange: DateRangeType = DATE_RANGE_30_DAYS;
  @tracked start = '';
  @tracked end = '';
  @tracked status = 0;
  @tracked promotionSubTypeIds = '';
  @tracked isLoading = false;

  allOption: FilterType = {
    name: 'All',
    id: 0,
  };
  dateRanges = DATE_RANGES;

  get reportType(): ReportType {
    return 'OrganizationPerformance';
  }

  get orgId() {
    return this.current.organization.id;
  }

  get isChain() {
    return this.current.organization.isChain;
  }

  get childrenOrganizationsOptions() {
    return [this.allOption, this.current.organization, ...this.current.organization.descendants];
  }

  @computed('allOption', 'current.organization.descendants.@each.id', 'tempSelectedOrgs')
  get selectedOrgs() {
    const selectedOrgIds = this.tempSelectedOrgs ? this.tempSelectedOrgs.split(',') : [];
    if (isEmpty(selectedOrgIds)) return [this.allOption];
    return [this.current.organization, ...this.current.organization.descendants].filter((org: FilterType) =>
      selectedOrgIds.includes(`${org.id}`)
    );
  }

  @computed('isChain', 'view')
  get isSummary() {
    return this.isChain && this.view == 'summary';
  }

  @computed('dateRange', 'start')
  get startDate() {
    const { dateRange, start } = this;
    const { startDate } = determineDateRange(dateRange, start);
    return startDate.toDate();
  }

  set startDate(value) {
    const startDate = moment(value).startOf('day');
    if (this.dateRange === DATE_RANGE_CUSTOM) {
      set(this, 'start', startDate.toISOString());
    }
  }

  @computed('dateRange', 'end')
  get endDate() {
    const { dateRange, end } = this;
    const { endDate } = determineDateRange(dateRange, undefined, end);
    return endDate.toDate();
  }

  set endDate(value) {
    const endDate = moment(value).endOf('day');
    if (this.dateRange === DATE_RANGE_CUSTOM) {
      set(this, 'end', endDate.toISOString());
    }
  }

  downloadReportTask = task({ drop: true }, async () => {
    const {
      name,
      startDate,
      endDate,
      organizationIds,
      promotionSubTypeIds,
      status,
      type,
      category,
      sortColumn,
      sortDirection,
    } = this;
    try {
      await this.reports.download(this.reportType, {
        params: {
          searchValue: name,
          sortColumn: sortColumn || '',
          sortDirection: sortDirection || '',
        },
        data: {
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          promotion_sub_type_ids: promotionSubTypeIds,
          organization_ids: organizationIds,
          is_active: status ? status == 1 : null,
          message_campaign_type_id: type,
          message_campaign_category_id: category,
          message_campaign_name: name,
        },
      });
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  filterByNameTask = task({ restartable: true }, async ({ target }: InputEvent & { target: HTMLInputElement }) => {
    await timeout(duration(200));
    this.updateSearchValue(target.value);
  });

  @action
  filterByDate(dateRange: DateRangeType) {
    if (dateRange === DATE_RANGE_CUSTOM) {
      setProperties(this, {
        page: 1,
        dateRange,
        start: this.startDate.toISOString(),
        end: this.endDate.toISOString(),
      });
    } else {
      const { startDate, endDate } = determineDateRange(dateRange);
      setProperties(this, {
        page: 1,
        dateRange,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        start: '',
        end: '',
      });
    }
  }

  @action
  filterByProperty(property: 'tempSelectedTypes' | 'tempSelectedOrgs', items: FilterType[]) {
    const lastSelectedId = items.lastObject?.id;
    this[property] = lastSelectedId == 0 ? '' : items?.mapBy('id')?.join(',');
  }

  @action
  changePage(page: number) {
    this.page = page;
  }

  @action
  handleSortChange({ column, direction }: { column: string; direction: SortDirectionType }) {
    setProperties(this, {
      sortColumn: column,
      sortDirection: direction,
      page: 1,
    });
  }

  @action
  clearAllFilters() {
    setProperties(this, {
      tempSelectedOrgs: '',
      tempSelectedTypes: '',
      view: 'summary',
      page: 1,
      organizationIds: '',
      type: null,
      category: undefined,
      name: '',
      status: 0,
      promotionSubTypeIds: '',
      dateRange: DATE_RANGE_30_DAYS,
      start: '',
      end: '',
    });
  }

  private updateSearchValue = (value: string) => {
    setProperties(this, {
      page: 1,
      name: value,
    });
  };
}
