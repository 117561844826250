/* eslint-disable ember/no-computed-properties-in-native-classes */
import { action, computed, set } from '@ember/object';
import { gt, sort } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import displayableFields from 'partner/utils/displayable-fields';
import { getFieldTypeName } from 'partner/utils/field-type-metadata';
import findDuplicateOptins from 'partner/utils/find-duplicate-optins';
import { proxy } from 'secondstreet-common/utils/promise-proxy';

const SEARCH_RESULTS_TRUNCATION_LENGTH = 100;

const fieldTypes = [
  'FacebookLikeApi',
  'SingleCheckbox',
  'Checkboxes',
  'CustomDateInput',
  'NumberInput',
  'Textbox',
  'SelectSingle',
  'RadioButtons',
  'Textarea',
  'WatchVideo',
  'DisplayText',
  'TwitterFollowApi',
  'TwitterTweetApi',
  'WebLink',
  'MobileApp',
  'SmartSpeakerSkill',
  'Instagram',
  'FacebookLink',
  'CodewordForExtraChances',
  'XLink',
];

export default class FormDesignerAddFormFieldComponent extends Component {
  @service store;
  @service permissions;
  @service enums;
  @service current;
  @service router;

  //region Properties
  @tracked
  searchText = '';

  @tracked
  searchInput = '';

  @tracked
  searchPromise = null;

  @tracked
  showingUnstarredItems = false;

  searchSorting = ['relevance:desc'];

  @tracked
  searchResults = [];
  //endregion

  //region Computed Properties
  get canCreateCustomFields() {
    return this.args.canCreateCustomFields ?? true;
  }

  get isEditingDefaultInheritedForm() {
    return (
      this.router.currentRouteName == 'organizations.organization.forms.form' && this.args.formPage?.form?.isInherited
    );
  }

  @computed('isExtraChances')
  get visibleFieldType() {
    return this.args.isExtraChances ? 'Custom' : 'Starred';
  }
  set visibleFieldType(value) {
    return value;
  }

  @computed('fieldTypeBlacklist', 'permissions.permissions.@each.permissionTypeId')
  get creatableFields() {
    const blacklist = this.args.fieldTypeBlacklist || [];
    const canAdministerOptins = this.permissions.getAccessLevel('GlobalOptin').administer;
    const fieldTypesFiltered = fieldTypes
      .concat(canAdministerOptins ? ['Optin'] : [])
      .reject(fieldType => blacklist.includes(fieldType));
    return fieldTypesFiltered.map(fieldType => ({
      fieldType,
      fieldTypeIconKey: this.enums.findWhere('FIELD_TYPE', { name: fieldType }, 'iconKey'),
      name: getFieldTypeName(fieldType),
    }));
  }

  @computed('starredFields.@each.fieldType', 'fieldTypeBlacklist.[]')
  get allowedStarredFields() {
    return this.args.starredFields.reject(field => this.args.fieldTypeBlacklist.includes(field.fieldType));
  }

  @computed('unstarredFields.@each.fieldType', 'fieldTypeBlacklist.[]')
  get allowedUnstarredFields() {
    return this.args.unstarredFields.reject(field => this.args.fieldTypeBlacklist.includes(field.fieldType));
  }

  @computed('searchResults.@each.fieldType', 'fieldTypeBlacklist.[]')
  get allowedSearchResults() {
    return this.searchResults.reject(field => this.args.fieldTypeBlacklist.includes(field.fieldType));
  }

  @computed('searchResults.@each.fieldType', 'fieldTypeBlacklist.[]')
  get blacklistedSearchResults() {
    return this.searchResults.filter(field => this.args.fieldTypeBlacklist.includes(field.fieldType));
  }

  @computed('allowedSearchResults.@each.id')
  get displayedSearchResults() {
    return displayableFields(this.allowedSearchResults, this.args.formPage);
  }

  @computed('allowedStarredFields.@each.id')
  get displayedStarredFields() {
    return displayableFields(this.allowedStarredFields, this.args.formPage);
  }

  @computed('allowedUnstarredFields.@each.id')
  get displayedUnstarredFields() {
    return displayableFields(this.allowedUnstarredFields, this.args.formPage);
  }

  @computed('sortedSearchResults.[]')
  get truncatedSearchResults() {
    return this.sortedSearchResults.slice(0, SEARCH_RESULTS_TRUNCATION_LENGTH);
  }

  @computed('searchResults.@each.globalOptin')
  get searchResultsDuplicates() {
    return findDuplicateOptins(this.searchResults);
  }

  @computed('displayedStarredFields.@each.globalOptin')
  get starredFieldsDuplicates() {
    return findDuplicateOptins(this.displayedStarredFields);
  }

  @computed('formPage.formFields.@each.fieldType')
  get hasAddedFriendReferralToForm() {
    return this.args.formPage.formFields.mapBy('fieldType').includes('ExtraChances');
  }

  @sort('displayedSearchResults', 'searchSorting')
  sortedSearchResults;

  @gt('sortedSearchResults.length', SEARCH_RESULTS_TRUNCATION_LENGTH)
  areSearchResultsTruncated;
  //endregion

  //region Methods
  /**
   * @private
   */
  _updateSearch() {
    if (!this.isDestroyed) {
      this.searchText = this.searchInput;
      this._searchFields(this.searchText);
    }
  }
  _searchFields(searchText) {
    if (isPresent(searchText)) {
      const params = { searchText };

      if (this.args.organizationPromotion) {
        params.organizationPromotionId = this.args.organizationPromotion.id;
      }

      this.searchPromise = proxy(
        this.store.query('field', params).then(fields => {
          set(
            this,
            'searchResults',
            this.isEditingDefaultInheritedForm
              ? fields.filterBy('organizationId', +this.current.organization.id)
              : fields
          );
        })
      );
    }
  }
  finish() {
    this.searchInput = '';
    this.searchText = '';
    this.args.finished();
  }
  //endregion

  //region Actions
  @action
  addExistingFieldToForm(field) {
    this.args.createFormField(this.args.formPage, field);
    this.args.somethingChanged();
    this.finish();
  }

  @action
  createAndEditField(fieldType) {
    const field = this.args.createField(fieldType);
    const formField = this.args.createFormField(this.args.formPage, field);
    this.args.editFormField(formField);
    this.finish();
  }

  @action
  cancel() {
    this.finish();
  }

  @action
  updateSearch({ target: { value } }) {
    this.searchInput = value;
    debounce(this, this._updateSearch, 250);
  }
  //endregion
}
