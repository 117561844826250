/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';
import { joinStringArray } from '@ss/helpers/helpers/join-string-array';

const { attr, belongsTo, hasMany } = DS;

/**
 * @typedef {DS.Model} DesignTemplate
 * @implements DesignTemplateModel
 */
export default BaseModel.extend({
  //region Dependencies
  enums: service(),
  //endregion

  //region Attributes
  name: attr('string'),
  templateContent: attr('string'),
  isInherited: attr('boolean', { defaultValue: false }),
  designTemplateTypeId: attr('number'),
  statusTypeId: attr('number'),
  designMethodTypeId: attr('number'),
  promotionTypeIds: attr(), // an array of integers
  useDefaultContent: attr('boolean'),
  organizationId: attr('number'),
  sourceDesignTemplateId: attr('number'),
  isLocked: attr('boolean'),
  //endregion

  //region Relationships
  organization: belongsTo('organization'),
  thumbnailMediaItem: belongsTo('media-item'),
  designTokens: hasMany('design-token', { async: false }),
  designTemplatePromotionTypes: hasMany('design-template-promotion-type', { async: false }),
  //endregion

  //region Computed Properties
  tokens: alias('designTokens'),
  designTemplateType: enums.computed('name', 'designTemplateTypeId', 'designTemplateTypeId', 'TEMPLATE_TYPE'),
  designMethodType: enums.computed('name', 'designMethodTypeId', 'designMethodTypeId', 'DESIGN_METHOD_TYPE'),
  statusType: enums.computed('name', 'statusTypeId', 'statusTypeId', 'STATUS_TYPE'),

  isNameDirty: dirtyProperty('name'),
  isLockedDirty: dirtyProperty('isLocked'),

  defaultPromotionTypeIds: computed('designTemplatePromotionTypes.[]', function () {
    return this.designTemplatePromotionTypes
      .toArray()
      .mapBy('promotionTypeId')
      ?.filter(promotionType => !promotionType.isDeleted);
  }),

  displayedPromotionTypeIds: computed('defaultPromotionTypeIds', function () {
    return this.defaultPromotionTypeIds.slice().uniq();
  }),

  displayedPromotionList: computed('displayedPromotionTypeIds.[]', function () {
    const displayedPromotions = this.displayedPromotionTypeIds.map(id =>
      this.enums.findWhere('PROMOTION_TYPE', { id }, 'publicName')
    );
    return displayedPromotions.length ? joinStringArray([displayedPromotions]) : '';
  }),
  //endregion
});
