import SettingsInfoController from 'partner/controllers/organizations/organization/settings/info';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { endpoint } from 'secondstreet-common/utils/url';
import { task } from 'ember-concurrency';

import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type SessionService from 'partner/services/-private/session';
import type CurrentService from 'partner/services/current';
import type { Model } from 'partner/routes/organizations/organization/stripe';

export default class OrganizationsOrganizationStripeController extends SettingsInfoController {
  @service declare current: CurrentService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare session: SessionService;

  declare model: Model;

  @tracked invalidSecretKeyError: string | null = null;
  @tracked invalidPublishableKeyError: string | null = null;
  @tracked apiSecretKey = '';
  @tracked apiPublishKey = '';
  @tracked paymentProcessor: any;
  @tracked isEditing = false;

  get isConnected() {
    return !!this.paymentProcessor?.isValid;
  }

  @action
  apiSecretKeyChanged(key: string) {
    this.invalidSecretKeyError = null;
    this.apiSecretKey = key;
  }

  @action
  apiPublishKeyChanged(key: string) {
    this.invalidPublishableKeyError = null;
    this.apiPublishKey = key;
  }

  @action
  setup() {
    this.paymentProcessor = this.model.organizationPaymentProcessors.find(
      processor => processor.paymentProcessorId === 1
    );

    if (this.paymentProcessor) {
      this.apiSecretKey = this.paymentProcessor.paymentProcessorApiKey;
      this.apiPublishKey = this.paymentProcessor.paymentProcessorPublishApiKey;
    }
  }

  attemptConnect = task({ drop: true }, async () => {
    const params = new URLSearchParams({
      stripeKey: this.apiSecretKey,
      stripePublishKey: this.apiPublishKey,
    }).toString();

    const response = await fetch(`${endpoint('stripe_connect') as string}?${params}`, {
      method: 'POST',
      headers: {
        ...(this.session.headers as HeadersInit),
      },
    });

    if (!response.ok) {
      const json = await response.json();
      this.invalidSecretKeyError = json.errors?.firstObject.message;
      return;
    }

    const processors = await this.store.query('organization-payment-processor', {
      organizationId: this.current.organization.id,
    });

    this.paymentProcessor = processors.find((x: any) => x.paymentProcessorId === 1);
    this.invalidSecretKeyError = null;
    this.isEditing = false;
  });
}
