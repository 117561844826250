/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class OrganizationsOrganizationLegalDesignTokensController extends Controller {
  //region Ember Hooks
  queryParams = ['designTokenId'];
  //endregion

  //region Dependencies
  @service store;
  //endregion

  //region Properties
  @tracked
  designTokenId = null;

  @tracked
  isResetting = false;
  //endregion

  //region Computed Properties
  @computed('model.legalDesignTokens', 'designTokenId')
  get activeDesignToken() {
    return this.model.legalDesignTokens.findBy('id', this.designTokenId);
  }

  @computed('activeDesignToken.{isInherited,currentDefaultContent.isInherited}')
  get editingOwnDefaultForInheritedToken() {
    return this.activeDesignToken.isInherited && !this.activeDesignToken.currentDefaultContent.isInherited;
  }

  @or('editingOwnDefaultForInheritedToken', 'activeDesignToken.currentDefaultContent.hasDirtyAttributes')
  saveEnabled;
  //endregion

  //region Methods
  createCustomDefaultContent() {
    const newTokenDefaultContent = this.store.createRecord('design-token-content', {
      designToken: this.activeDesignToken,
      isInherited: false,
      value: this.activeDesignToken.inheritedDefaultContent.value,
    });
    this.activeDesignToken.inheritedDefaultContent.rollbackAttributes();
    this.activeDesignToken.defaultDesignTokenContents.addObject(newTokenDefaultContent);
    return newTokenDefaultContent;
  }
  closeEditor() {
    this.designTokenId = null;
  }
  //endregion

  //region Actions
  @action
  reset() {
    this.activeDesignToken.customDefaultContent.destroyRecord();
    this.isResetting = false;
    this.closeEditor();
  }

  @action
  cancel() {
    this.activeDesignToken.currentDefaultContent.rollbackAttributes();
    this.closeEditor();
  }

  @action
  async save() {
    const { currentDefaultContent } = this.activeDesignToken;

    if (!currentDefaultContent.hasDirtyAttributes) {
      return this.closeEditor();
    }

    if (currentDefaultContent.isInherited) {
      await this.createCustomDefaultContent().save();
      return this.closeEditor();
    }

    if (this.activeDesignToken.currentDefaultContentIsEqualToInherited) {
      await this.activeDesignToken.customDefaultContent.destroyRecord();
      return this.closeEditor();
    }

    await currentDefaultContent.save();
    return this.closeEditor();
  }

  @action
  updateTokenContentProperty(property, value) {
    set(this, `activeDesignToken.currentDefaultContent.${property}`, value);
  }

  @action
  setIsResetting(bool) {
    this.isResetting = bool;
  }

  @action
  setDesignTokenId(designTokenId) {
    this.designTokenId = designTokenId;
  }
  //endregion
}
