import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CurrentService extends Service {
  @tracked organization: any;
  @tracked organizationPromotion: any;

  get promotion() {
    return this.organizationPromotion?.promotion;
  }

  setOrganization(organization: any) {
    this.organization = organization;
  }

  setOrganizationPromotion(organizationPromotion: any) {
    this.organizationPromotion = organizationPromotion;
  }

  toParams() {
    return {
      ...(this.organization ? { organizationId: this.organization.id } : {}),
      ...(this.organizationPromotion ? { organizationPromotionId: this.organizationPromotion.id } : {}),
    };
  }

  /**
   * Returns the current organization and promotion as a hash of params
   * using the `hasSyndicates` flag to determine whether to use the
   * `organizationPromotionId` or `promotionId` param.
   *
   * It should be used with records that are syndicated, such as
   * `categorical-chart`, `event-chart`, etc.
   */
  toSyndicatedParams() {
    return {
      ...(this.organization ? { organizationId: this.organization.id } : {}),
      ...(this.organizationPromotion
        ? this.organizationPromotion.hasSyndicates
          ? { promotionId: this.organizationPromotion.promotion.id }
          : { organizationPromotionId: this.organizationPromotion.id }
        : {}),
    };
  }
}
