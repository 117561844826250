/* eslint-disable ember/use-ember-get-and-set, ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

const NESTED_ROUTES = ['organizations.organization.organization-promotions.organization-promotion.setup.ballot'];

/**
 * Controller for /o/:organization_id/op/:organization_promotion_id/setup
 */
export default Controller.extend({
  //region Ember Hooks
  router: service(),
  checklist: service(),
  organizationPromotionController: controller(
    'organizations.organization.organization-promotions.organization-promotion'
  ),

  currentSetupStep: computed('router.currentRouteName', function () {
    const route =
      NESTED_ROUTES.find(route => this.router.currentRouteName.startsWith(route)) || this.router.currentRouteName;

    return route.substring(route.lastIndexOf('.') + 1);
  }),

  //endregion

  //region Computed Properties
  organizationPromotion: alias('organizationPromotionController.model.organizationPromotion'),
  /**
   * This is used to make certain sections "dirty" even when none of the models are actually dirty.
   * This "dirty" states refers to the step being incomplete, which makes the save button green.
   *
   * This is sort of faking the "dirty" state to force the user to click save on certain routes.
   */
  hasDirtyAttributes: computed('router.currentRouteName', 'model.checklistSteps.@each.isComplete', function () {
    const allSteps = this.get('model.checklistSteps');
    if (allSteps) {
      const checklistStepTypeId = this.checklist.getChecklistStepId(this.router.currentRouteName);
      const currentStep = allSteps.findBy('checklistStepTypeId', checklistStepTypeId);

      return currentStep ? !currentStep.get('isComplete') : false;
    }
    return false;
  }),
  /**
   * @type {Matchup[]} - all the Matchups
   */
  allMatchups: computed('model.matchupViews.@each.matchups', function () {
    return get(this, 'model.matchupViews').map(x => get(x, 'matchup'));
  }),
});
