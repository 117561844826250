import type Store from '@ember-data/store';

export function unloadEmptyRecords(store: Store, modelName: string) {
  if (!modelName || !store) return;

  store
    .peekAll(modelName)
    .rejectBy('id')
    .forEach(record => {
      store.unloadRecord(record);
    });
}
